import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import TopNavBar from "../../components/TopNavBar";
import MoreIcon from "../../../src/assets/png/More.png";
import MeetingDemoIcon from "../../../src/assets/png/Mask group.png";
import EditButtonIcon from "../../../src/assets/png/EditButtonIcon.png";
import DeleteButtonIcon from "../../../src/assets/png/DeletebuttonIcon.png";
import { ReactComponent as EditSvgIcon } from "../../../src/assets/svg/EditSvgIcon.svg";
import "./MeetingRooms.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { fetchAllData } from "../../utils/fetchData";
import { useQuery } from "react-query";
import Pagination from "../../components/navbarwidgets/Pagination";
import LoadingOverlay from "react-loading-overlay-ts";
import { toast } from "react-toastify";
import LoaderSpinner from "../../utils/LoaderSpinner";

function MeetingRooms() {
  const apiURL = process.env.REACT_APP_API_URL;
  const meetingEndpoint = "/v1/rooms";

  const navigate = useNavigate();

  const [meetingData, setMeetingData] = useState([]);
  const [showExtraClass, setShowExtraClass] = useState(true); //usestate for animation
  const [loadingState, setLoadingState] = useState(true);
  const { data, isLoading } = useQuery(
    ["Meeting", meetingEndpoint],
    () => fetchAllData(meetingEndpoint),
    {
      onSuccess: (data) => {
        setMeetingData(data);
      },
    }
  );

  const sortedRoomsData = meetingData.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  // Pagination code
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const totalItems = meetingData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const newMeetingData = sortedRoomsData.slice(startIndex, endIndex);

  // Resize animation
  useEffect(() => {
    const handleResize = () => {
      setShowExtraClass(window.innerWidth > 450);
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    if (!isLoading) {
      const timer = setTimeout(() => {
        setIsInitialLoading(false);
      }, 3000); // Change to 5000 for 5 seconds if desired

      return () => clearTimeout(timer); // Cleanup timer on unmount
    }
  }, [isLoading]);

  // Edit meeting
  const editMeetinghandle = (room) => {
    navigate("/editrooms", { state: room });
  };

  // Delete functionality
  const deleteMeetinghandle = async (roomId) => {
    try {
      const token = localStorage.getItem("token");
      await axios.delete(`${apiURL}/v1/rooms/${roomId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
      });
      const deletedMeeting = meetingData.find(
        (meeting) => meeting.id === roomId
      );
      toast.success(`${deletedMeeting.title.split("|")} is deleted`);
      window.location.reload();
    } catch (error) {
      console.error("Error deleting meeting:", error.message);
    }
  };

  return (
    <div className="navBarWrapper">
      <Sidebar />
      <div className="contentWrapper">
        <TopNavBar />
        <div className="meetingRoomWrapper">
          <div className="topMeetingRoomSection">
            <button className="addNewButton">
              <Link to="/addnewrooms">
                <span>
                  <img src={MoreIcon} alt="" />
                </span>
                Add new
              </Link>
            </button>
            <h3>Spaces ({meetingData?.length || 0})</h3>
          </div>
          <div className="meetingCollectionWrapper">
            {isLoading || isInitialLoading ? (
              <div className="spinner-overlay">
                <LoaderSpinner />
              </div>
            ) : newMeetingData.length === 0 ? (
              <p>There is currently no meeting rooms to display</p>
            ) : (
              newMeetingData.map((meeting) => {
                const [roomTitle, roomCapacity] = meeting.title.split("|");
                return (
                  <div
                    className={`meetingDiv ${
                      showExtraClass ? "extraClass" : ""
                    }`}
                    key={meeting.id}
                    id="itemCardId"
                  >
                    <div className="hovercolorbubble"></div>
                    <img
                      src={meeting.imageUrl[0]}
                      alt=""
                      className="meetingRoomImgClass"
                    />
                    <div className="meetingDetailWrapper">
                      <h2>
                        {roomTitle}
                        {roomCapacity > 1 ? `(${roomCapacity} seats)` : ""}
                      </h2>
                      <p>{meeting.description}</p>
                      <div className="buttonSection">
                        <button onClick={() => editMeetinghandle(meeting)}>
                          <span>
                            <EditSvgIcon />
                          </span>
                          Edit
                        </button>
                        <button onClick={() => deleteMeetinghandle(meeting.id)}>
                          <span>
                            <img src={DeleteButtonIcon} alt="" />
                          </span>
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
          {meetingData.length > 6 && (
            <div className="pagWrapper margExtra">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPrevious={handlePrevious}
                onNext={handleNext}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MeetingRooms;
