export const Asterisk = () => {
  return <span style={{ color: "red", fontSize: "18px" }}>*</span>;
};

export const AsteriskText = () => {
  return (
    <span
      style={{
        fontFamily: "Nunito",
        fontStyle: "italic",
        display: "block",
        width: "100%",
        textAlign: "center",
        paddingTop: "1%",
      }}
    >
      Fields with asterisk (*) are required
    </span>
  );
};

export const extractFirstValue = (str, separator) => {
  if (str) {
    const strArray = str?.split(separator);
    const firstValue = strArray[0];
    return firstValue;
  }
};
