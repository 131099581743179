// ReviewRow.js
import React, { useState } from 'react';
import axios from "axios";
import { toast } from "react-toastify";
import ToggleSwitch from '../../../components/UIComponent/ToggleSwitch';
import StarIcon from '../StarIcon';

const CoachReviewRow = ({ item, index, refreshData }) => {
  const [isDisabled, setIsDisabled] = useState(item.isDisabled);
  const [loading, setLoading] = useState(false);
  console.log("isDisabled", isDisabled)

  const handleToggle = async () => {
    const updatedIsDisabled = !isDisabled; // Get the new isDisabled value
    try {
      setLoading(true);
      // Update the review visibility via the API
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/v1/coachreviews/updateVisibility/${item.id}`,
        {
          isDisabled: updatedIsDisabled,
        }
      );
      const result= response.data.body[0].isDisabled;
      result === true ? toast.success("Review has been disabled successfully!")  : toast.success("Review has been enabled successfully")
      // Update local state with the new visibility value
      setIsDisabled(updatedIsDisabled);
      refreshData(); // Refresh the data if needed
    } catch (error) {
      console.error(error.message);
      toast.error("Error updating review visibility:", error.message);
    } finally {
      setLoading(false);
    }
  };


  const renderStarIcons = () => {
    const stars = [];
    for (let i = 0; i < item.rating; i++) {
      stars.push(
        <StarIcon
          key={i} // unique key for each star
          color="#FFD700" // Gold color for filled stars
          width="20" // Adjust width for the star size
          height="20"
        />
      );
    }
    return (
      <div className="stars-container">
        {stars}
      </div>
    );
  };

  return (
    <tr key={index}>
      <td>{item?.coach?.title}</td>
      <td>{item?.coachBooking?.checkInDate}</td>
      <td>{item?.coachBooking?.checkInTime}</td>
      <td>{item?.coachBooking?.duration} hours</td>
      <td>{renderStarIcons()}</td>
      <td>{item?.description}</td>
      <td>
        {item?.coachBooking?.isReviewed ? "Reviewed" : "Not Reviewed"}
      </td>
      <td>
        <ToggleSwitch
          defaultChecked={isDisabled} // Use isDisabled state directly
          onChange={handleToggle} // Handle toggle change
          disabled={loading} // Disable while loading
        />
      </td>
    </tr>
  );
};

export default CoachReviewRow;
