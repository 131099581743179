import Sidebar from "../../components/Sidebar";
import TopNavBar from "../../components/TopNavBar";
import LoadingOverlay from "react-loading-overlay-ts";
import "./events.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Asterisk } from "../../utils/Asterisk";
import NavigateBack from "../../utils/NavigateBack";

const CreateEvent = () => {
  const [fileDetails, setFileDetails] = useState(null);
  const [formDetails, setFormDetails] = useState({
    eventName: "",
    eventDate: "",
    eventStartTime: "",
    eventEndTime: "",
    eventCapacity: "",
    eventAbout: "",
  });

  const [isEditMode, setIsEditMode] = useState(false);
  const [inputTypes, setInputTypes] = useState({
    eventStartTime: "text",
    eventEndTime: "text",
  });

  const handleFocus = (field) => {
    setInputTypes((prevTypes) => ({
      ...prevTypes,
      [field]: "time",
    }));
  };

  const handleBlur = (field) => {
    setInputTypes((prevTypes) => ({
      ...prevTypes,
      [field]: "time",
    }));
  };
  const router = useNavigate();

  const extractTimeFromTimestamp = (timestamp) => {
    let dateObj = new Date(timestamp);

    let hours = dateObj.getHours().toString().padStart(2, "0");
    let minutes = dateObj.getMinutes().toString().padStart(2, "0");

    return `${hours}:${minutes}`;
  };

  const convertIsoToYyyyMmDd = (isoDateStr) => {
    const date = new Date(isoDateStr);
    const yyyy = date.getUTCFullYear();
    const mm = String(date.getUTCMonth() + 1).padStart(2, "0");
    const dd = String(date.getUTCDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  useEffect(() => {
    const eventDetails = localStorage.getItem("eventDetails");
    if (eventDetails) {
      try {
        const parsedEventDetails = JSON.parse(eventDetails);
        parsedEventDetails.eventStartTime = extractTimeFromTimestamp(
          parsedEventDetails?.eventStartTime
        );
        parsedEventDetails.eventEndTime = extractTimeFromTimestamp(
          parsedEventDetails?.eventEndTime
        );
        parsedEventDetails.eventDate = convertIsoToYyyyMmDd(
          parsedEventDetails?.eventDate
        );
        setFormDetails(parsedEventDetails);
        setIsEditMode(true);
      } catch (error) {
        console.error("Error parsing eventDetails from localStorage:", error);
      }
    }
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileDetails(file);
    } else {
      setFileDetails(null);
    }
  };

  const convertToDateFormat = (timeString) => {
    const currentDate = new Date();
    const [hours, minutes] = timeString.split(":").map(Number);
    currentDate.setHours(hours);
    currentDate.setMinutes(minutes);
    return currentDate;
  };

  const handleInput = (e) => {
    setFormDetails((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const today = new Date().toISOString().split('T')[0];

  const createEvent = async () => {
    try {
      const token = localStorage.getItem("token");
      const formData = new FormData();
      formData.append("file", fileDetails);

      const imageUpload = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/files`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const updatedFormDetails = {
        ...formDetails,
        eventDate: new Date(formDetails.eventDate),
        eventStartTime: convertToDateFormat(formDetails.eventStartTime),
        eventEndTime: convertToDateFormat(formDetails.eventEndTime),
        eventImage: imageUpload.data.body[0]?.filename,
      };

      const eventResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/events`,
        updatedFormDetails,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      router("/events");
    } catch (error) {
      console.error("Error submitting event:", error);
    }
  };

  const updateEvent = async () => {
    try {
      let imageUpload;
      const token = localStorage.getItem("token");
      if (fileDetails?.size) {
        const formData = new FormData();
        formData.append("file", fileDetails);

        imageUpload = await axios.post(
          `${process.env.REACT_APP_API_URL}/v1/files`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      const updatedFormDetails = {
        ...formDetails,
        eventDate: new Date(formDetails.eventDate),
        eventStartTime: new Date(
          `${formDetails.eventDate}T${formDetails.eventStartTime}:00`
        ),
        eventEndTime: new Date(
          `${formDetails.eventDate}T${formDetails.eventEndTime}:00`
        ),
        eventImage:
          fileDetails?.size !== 0
            ? imageUpload.data.body[0]?.filename
            : formDetails?.eventImage,
      };

      const eventResponse = await axios.put(
        `${process.env.REACT_APP_API_URL}/v1/events/${formDetails?.id}`,
        updatedFormDetails,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      router("/events");
    } catch (error) {
      console.error("Error submitting event:", error);
    }
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    if (isEditMode) {
      updateEvent();
    } else {
      createEvent();
    }
  };

  return (
    <LoadingOverlay active={false} spinner text="Loading your content...">
      <div className="navBarWrapper">
        <Sidebar />
        <div className="contentWrapper">
          <TopNavBar />
          <div className="create_eventViewWrapper">
            <div className="svg-container">
              <NavigateBack
                fill={"#66a3a6"}
                onClick={() => (window.location.href = "/events")}
              />
            </div>
            <div className="create_event_form" >
              <form onSubmit={submitHandler} className="create-event-form-container">
                <div className="create-event-image-upload-container">
                  <label>
                    Event Image
                    <Asterisk />
                  </label>
                  <div className="event_form_field">
                    <div className="file_button_wrapper">
                      <input type="file" onChange={handleFileChange} required />
                      <span>Upload</span>
                    </div>
                    {fileDetails && <span>{fileDetails?.name}</span>}
                  </div>
                </div>
                <div className="form_input_fields">
                  <input
                    type="text"
                    className="event_form_input_field"
                    name="eventName"
                    placeholder="Event Name*"
                    onChange={handleInput}
                    required
                    value={formDetails.eventName}
                  />
                  <input
                    type="date"
                    className="event_form_input_field"
                    name="eventDate"
                    placeholder="Event Date"
                    onChange={handleInput}
                    required
                    value={formDetails.eventDate}
                    min={today}
                  />
                  <input
                    type={inputTypes.eventStartTime}
                    className="event_form_input_field"
                    name="eventStartTime"
                    placeholder="Start Time*"
                    onChange={handleInput}
                    onFocus={() => handleFocus("eventStartTime")}
                    onBlur={() => handleBlur("eventStartTime")}
                    required
                    value={formDetails.eventStartTime}
                  />

                  <input
                    type={inputTypes.eventEndTime}
                    className="event_form_input_field"
                    name="eventEndTime"
                    placeholder="End Time*"
                    onChange={handleInput}
                    onFocus={() => handleFocus("eventEndTime")}
                    onBlur={() => handleBlur("eventEndTime")}
                    required
                    value={formDetails.eventEndTime}
                  />
                  <input
                    type="number"
                    className="event_form_input_field"
                    name="eventCapacity"
                    placeholder="Capacity*"
                    onChange={handleInput}
                    required
                    min={0}
                    value={formDetails.eventCapacity}
                  />
                  <input
                    type="text"
                    className="event_form_input_field"
                    name="eventAbout"
                    placeholder="About Event*"
                    onChange={handleInput}
                    required
                    value={formDetails.eventAbout}
                  />
                </div>
                <div className="publish_button_container">
                  <button type="submit" className="event_publish_button">
                    Publish
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default CreateEvent;
