import React, { useState } from 'react'
import "./Pagination.css"

function Pagination({ currentPage, totalPages, onPrevious, onNext }) {

// Pagination
  return (
    <>
     <div className="pagination">
      <button
        className="prevButton"
        disabled={currentPage === 1}
        onClick={onPrevious}
      >
        Previous
      </button>
      <button
        className="nextButton"
        disabled={currentPage === totalPages}
        onClick={onNext}
      >
        Next
      </button>
    </div>
    </>
  )
}
export default Pagination