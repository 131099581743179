import React, { useState } from "react";
import { createFilter, fetchFilters,deleteFilterHandler,editFilterHandler } from '../../../utils/filtersOperations';
import { useQuery } from "react-query";
import LoadingOverlay from 'react-loading-overlay-ts';
import { toast } from "react-toastify";
function FiltersCoachesScreen() {
  const skills = "skills"
  const [filterPanelOpen, setfilterPanelOpen] = useState(false);
  const [coachesSkills, setCoachesSkills] = useState([]);
  const [newSkills, setNewSkills] = useState({name:""});
  const { data, isLoading } = useQuery(['skills',skills],
  ()=>fetchFilters(skills),
  {onSuccess:(data)=>{
    setCoachesSkills(data.map((obj,index)=>{return {id:obj.id,name:obj.title}}))
  }})


  // filter panel 
  const viewHandle = () => {
    setfilterPanelOpen(true);
    setNewSkills({name:""});
  };

  const getRandomId = () =>{
    return Math.floor(Math.random() * 10000);
  }
  

  const filterChange = (e) =>{
    setNewSkills({...newSkills, name: e.target.value });
  }


  const saveFilterHandle = async (e,filterPanelOpen)=>{


    if (!(newSkills?.name) && !(newSkills.name.trim())) {
      toast.error("Please enter the details!");
      return;
    }
    if(filterPanelOpen === 'edit'){
      await editFilterHandler(setfilterPanelOpen,filterPanelOpen,coachesSkills,setCoachesSkills,"",newSkills,setNewSkills,skills);
      return
    }

    if (newSkills.name.trim() !== "") {

      const {id} = await createFilter(skills,newSkills.name)

      setCoachesSkills([
        ...coachesSkills,
        { id: id, name: newSkills.name },
      ]);
      // setNewEmpDesig("");
      
      setfilterPanelOpen(false);

    }
  }


  return (
    <>
      <LoadingOverlay
        active={isLoading}
        spinner
        text='Loading your content...'
        className="mobLoader"
      >
      <div className="tableContainer">
        <table className="screensTable">
            <thead>
              <tr>
                <th>Sr.no</th>
                
                <th>Field name</th>
                <th>Actions</th>  
              </tr>
            </thead>
            <tbody>
              {coachesSkills.map((facility, index) => (
                <tr key={facility.id}>
                  <td>{index + 1}</td>
                
                  <td>{facility.name}</td>
                  <td>
                    <div className="filterBtnClass">
                    <button onClick={()=>editFilterHandler(setfilterPanelOpen,filterPanelOpen,coachesSkills,setCoachesSkills,index,newSkills,setNewSkills,skills)}>Edit</button>
                    <button onClick={()=>deleteFilterHandler(coachesSkills,setCoachesSkills,index,skills)}>Delete</button>
                      </div>
                  </td>
                </tr>
              ))}
            </tbody>
            </table>
      </div>
      {/* add new filter panel  */}
      {filterPanelOpen && (
        <div className="addFilterOverlay">
          <div className="filterTableContentWrapper">
            <div className="addNewFilterHeading">
              <h2>add new filters</h2>
            </div>
            <div className="filterInputsWrapper">
              <h2>Add Coach Skills</h2>
              <div className="addfilterInputWrapper">
             <label htmlFor="roomFacilities">Skills:</label>
             <input type="text" name="roomFacilities" value={newSkills.name} onChange={filterChange}/>
             </div>
              <button
                className="filterOverlaySaveBtn"
                onClick={(event) => {
                  saveFilterHandle(event,filterPanelOpen);
                  setfilterPanelOpen(false);
                }}
              >
                Save
              </button>
              <button
                className="filterOverlaySaveBtn cancelBtn"
                onClick={(event) => {
                  setfilterPanelOpen(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="filterBtnWrapper">
        <button onClick={viewHandle}>Add Filter</button>
      </div>
      </LoadingOverlay>
    </>
  );
}

export default FiltersCoachesScreen;
