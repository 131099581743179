import './HamburgerMenuItem.css'

function HamburgerMenuItem({itemTitle,id,icon,url}){

    const handleOnClick = () =>{
        window.location.href = url;
    }

    return(

        <div onClick={handleOnClick} className="itemWrapper">
            <div className="itemAndIcon">
                <div className="icon-wrapper">{icon}</div>
                <p>{itemTitle}</p>
            </div>
            <hr />
        </div>
    )
}

export default HamburgerMenuItem;