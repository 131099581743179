import React from 'react';
import { ReactComponent as EditIcon } from "../../assets/svg/EditB.svg";
import { ReactComponent as DeleteIcon } from "../../assets/svg/DeleteB.svg";

const ActionMenu = ({ actions, onClose }) => {
  return (
    <div className="actionsContainer">
      {actions.map((action, index) => (
        <div key={index} className={`action ${action.label.toLowerCase()}`} onClick={action.handler}>
          {action.label === 'Update' && <EditIcon />}
          {action.label === 'Delete' && <DeleteIcon />}
          <span>{action.label}</span>
        </div>
      ))}
    </div>
  );
};

export default ActionMenu;
