import React from "react";
import Sidebar from "../../../components/Sidebar";
import TopNavBar from "../../../components/TopNavBar";
import { useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  dropHandler,
  dragOverHandler,
} from "../../../utils/imageDragDropHandlers";
import { useQuery, useQueries } from "react-query";
import LoadingOverlay from "react-loading-overlay-ts";
import { fetchFilters } from "../../../utils/filtersOperations";
import { fetchAllData } from "../../../utils/fetchData";
import { ReactComponent as CloseIcon } from "../../../assets/svg/closeImg.svg";
import { Asterisk, AsteriskText } from "../../../utils/Asterisk";
import NavigateBack from "../../../utils/NavigateBack";

const apiURL = process.env.REACT_APP_API_URL;

function EditStaffPage() {
  const filtersEndpoint = "designations";
  const meetingEndpoint = "/v1/rooms";
  const location = useLocation();
  const [staffData, setStaffData] = useState(() => {
    const [firstName, lastName] = location.state.name.split(" ");
    const inchargeId = location.state.incharge;
    return {
      ...location.state,
      firstName: firstName,
      lastName: lastName,
      inchargeId: inchargeId,
      inchargeRoom: "",
    };
  });
  const [selectedFile, setSelectedFile] = useState(location.state.image);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [fileChange, setFileChange] = useState(false);
  const [designations, setDesignations] = useState([]);
  const [rooms, setRooms] = useState([]);

  // const {data,isLoading} = useQuery(
  //   ['designationsFilters',filtersEndpoint],
  //   ()=>fetchFilters(filtersEndpoint),
  //   {onSuccess:(data)=>{
  //     setDesignations(data.map((designation)=>designation.title))
  //   }})
  // console.log(staffData)

  //   const {} = useQuery(
  //     ["Meeting", meetingEndpoint],
  //     () => fetchAllData(meetingEndpoint),
  //     {
  //       onSuccess: (data) => {
  //         // Perform actions on successful data retrieval
  //         const roomNames = [];

  //         data.forEach((room) => {
  //           roomNames.push(room.title);
  //         });

  //         setRooms(roomNames);
  //         console.log('meeting room data',roomNames)
  //         // Additional logic here if needed
  //       },
  //     }
  //   );

  const results = useQueries([
    {
      queryKey: ["designationsFilters", filtersEndpoint],
      queryFn: () => fetchFilters(filtersEndpoint),
      onSuccess: (data) => {
        const designations = data.map((designation) => designation.title);
        setDesignations(designations);
      },
    },
    {
      queryKey: ["Meeting", meetingEndpoint],
      queryFn: () => fetchAllData(meetingEndpoint),
      onSuccess: (data) => {
        const roomNames = [];
        data.forEach((room) => {
          roomNames.push(room.title);
        });
        setRooms(roomNames);

        const inchargeRoom = data.find(
          (room) => room.id === Number(staffData.inchargeId)
        );
        setStaffData((prevState) => {
          return { ...prevState, inchargeRoom: inchargeRoom };
        });
        console.log("meeting room data", roomNames);
      },
    },
  ]);

  console.log("staffData", staffData);

  const isLoading = results.some((query) => query.isLoading);

  console.log("designations", designations);

  const handleOnInputChange = (event) => {
    const { name, value } = event.target;
    setStaffData((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log(file);
    setSelectedFile(file);
    setFileChange(true);
  };

  const handleImageRemove = () => {
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleStaffEdit = async (e) => {
    console.log("Yea it works");
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      const imageFormData = new FormData();
      imageFormData.append("file", selectedFile);
      let imagePath;

      if (fileChange && selectedFile) {
        const imageUploadResponse = await axios.post(
          `${apiURL}/v1/files/`,
          imageFormData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        imagePath = imageUploadResponse.data.body[0].filename;
      }

      const formData = new FormData();
      console.log(staffData);
      formData.append("image", imagePath || staffData.image);
      formData.append("name", staffData.firstName + " " + staffData.lastName);
      formData.append("email", staffData.email);
      formData.append("designation", staffData.designation);
      formData.append("incharge", staffData.incharge);
      formData.append("description", staffData.description);
      console.log(formData);
      const response = await axios.patch(
        `${apiURL}/v1/staffs/${staffData.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json",
          },
        }
      );
      navigate("/staff");
      console.log("success:", response.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  console.log(staffData.id);

  return (
    <>
      <LoadingOverlay active={isLoading} spinner text="Loading your content...">
        <div className="navBarWrapper">
          <Sidebar />
          <div className="contentWrapper">
            <TopNavBar />
            {/* Content of the page */}
            <div className="addNewRoomWapper">
            <NavigateBack
                fill={"#66a3a6"}
                onClick={() => (window.location.href = "/staff")}
              />
              <div className="addNewFormWrapper">
                <form
                  onSubmit={handleStaffEdit}
                  action=""
                  className="addNewRoomForm"
                >
                  <h2>Edit Staff</h2>
                  <AsteriskText />
                  <div
                    className="fileInput"
                    onDragOver={dragOverHandler}
                    onDrop={(event) => {
                      dropHandler(event, setSelectedFile);
                      setFileChange(true);
                    }}
                  >
                    <label htmlFor="files">Select File</label>
                    <p
                      className={
                        selectedFile ? "showFileDetails" : "hideFileDetails"
                      }
                    >
                      {selectedFile ? (
                        <>
                          {selectedFile?.name || selectedFile}
                          <span
                            className="removeImage"
                            onClick={handleImageRemove}
                          >
                            <CloseIcon />
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                    </p>
                    <input
                      type="file"
                      name="image"
                      id="files"
                      style={{ display: "none" }}
                      accept="image/png, image/jpeg"
                      onChange={handleFileChange}
                      ref={fileInputRef}
                    />
                  </div>
                  <div className="addNewFormInputWrapper">
                    <label htmlFor="firstName">
                      First Name
                      <Asterisk />
                    </label>
                    <br />
                    <input
                      onChange={handleOnInputChange}
                      type="text"
                      name="firstName"
                      placeholder="Enter First Name"
                      value={staffData.firstName}
                    />
                  </div>
                  <div className="addNewFormInputWrapper">
                    <label htmlFor="lastName">
                      Last Name
                      <Asterisk />
                    </label>
                    <br />
                    <input
                      onChange={handleOnInputChange}
                      type="text"
                      name="lastName"
                      placeholder="Enter Last Name"
                      value={staffData.lastName}
                    />
                  </div>
                  {/* email */}
                  <div className="addNewFormInputWrapper">
                    <label htmlFor="emaill">
                      Email
                      <Asterisk />
                    </label>
                    <br />
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter email"
                      onChange={handleOnInputChange}
                      value={staffData.email}
                      disabled
                    />
                  </div>
                  <div className="addNewFormInputWrapper">
                    <label htmlFor="designation">
                      Employee Designation
                      <Asterisk />
                    </label>
                    <br />
                    <select onChange={handleOnInputChange} name="designation">
                      <option value="">Select One</option>
                      {designations.map((designation) => {
                        if (designation === staffData.designation) {
                          return (
                            <option value={designation} selected>
                              {designation}
                            </option>
                          );
                        } else {
                          return (
                            <option value={designation}>{designation}</option>
                          );
                        }
                      })}
                    </select>
                  </div>
                  {/*  */}
                  <div className="addNewFormInputWrapper">
                    <label htmlFor="incharge">
                      In-Charge
                      <Asterisk />
                    </label>
                    <br />
                    <select
                      value={staffData?.inchargeRoom?.title}
                      onChange={handleOnInputChange}
                      name="incharge"
                    >
                      <option>Select One</option>
                      {rooms.map((room, index) => {
                        if (room === staffData.incharge) {
                          return (
                            <option key={index} value={room} selected>
                              {room.split("|")[0]}
                            </option>
                          );
                        } else {
                          return (
                            <option key={index} value={room}>
                              {room.split("|")[0]}
                            </option>
                          );
                        }
                      })}
                    </select>
                  </div>
                  <div className="addNewFormInputWrapper descheight">
                    <label htmlFor="description">
                      Description
                      <Asterisk />
                    </label>
                    <br />
                    <textarea
                      value={staffData.description}
                      onChange={handleOnInputChange}
                      placeholder="Enter Description"
                      type="text"
                      name="description"
                    ></textarea>
                  </div>
                  <div className="addBtnWrapper">
                    <button type="submit">Add</button>
                  </div>
                </form>
              </div>
            </div>
            {/* Content of the page */}
          </div>
        </div>
      </LoadingOverlay>
    </>
  );
}

export default EditStaffPage;
