import React, { useContext, useEffect, useRef, useState } from "react";
import "./EditRoom.css";
import Sidebar from "../../../components/Sidebar";
import TopNavBar from "../../../components/TopNavBar";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  dragOverHandler,
  dropHandler,
} from "../../../utils/imageDragDropHandlers";
import { useQuery } from "react-query";
import { fetchFilters } from "../../../utils/filtersOperations";
import { ReactComponent as CloseIcon } from "../../../assets/svg/closeImg.svg";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import MyContext from "../../../utils/MyContext";
import { toast } from "react-toastify";
import { Asterisk, AsteriskText } from "../../../utils/Asterisk";
import NavigateBack from "../../../utils/NavigateBack";

function EditRoom() {
  const filtersEndpoint = "facilities";
  const apiURL = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const roomData = location.state; // full data of the rooom
  const roomId = roomData?.id; //ID of the room
  const [titleSeats, setTitleSeats] = useState([]);
  const [selectedFile, setSelectedFile] = useState(roomData?.files.split("|"));
  const [fileChange, setFileChange] = useState(false);
  const [facilities, setFacilities] = useState([]);
  const [newFacilities, setNewFacilities] = useState(roomData?.facilities);
  const fileInputRef = useRef(null);
  const animatedComponents = makeAnimated();

  const currentFacilities = roomData?.facilities.split("|");
  console.log(facilities);

  const { data, isLoading } = useQuery(
    ["skillsFilters", filtersEndpoint],
    () => fetchFilters(filtersEndpoint),
    {
      onSuccess: (data) => {
        setFacilities(
          data.map((facility) => {
            return { value: facility.title, label: facility.title };
          })
        );
        // const defaultFacilities = facilities.filter((facility,index) => currentFacilities.includes(facility.value));
      },
    }
  );

  console.log("selectedfile",selectedFile)

  // navigate function
  const navigate = useNavigate();

  // use states for input
  const [editRoom, setEditRoom] = useState({
    roomCredit: roomData?.credits,
    roomTitle: roomData?.title.split("|")[0],
    roomCapacity: roomData?.title.split("|")[1],
    roomFacilities: roomData?.facilities,
    description: roomData?.description,
    toggleQuickBooking: roomData?.quickbook,
  });

  const [roomTitle, roomCapacity] = roomData?.title.split("|");
  console.log("just checking", roomTitle, "room capacity:", roomCapacity);

  // handle change for all inputs
  const handleEditInputChange = (event, additionalDetails) => {
    if (additionalDetails?.name === "roomFacilities") {
      const filters = event.map((facility) => facility.value);
      setNewFacilities(filters.join("|"));
      return;
    }
    const { name, value, type, checked } = event.target;

    // if(name === 'roomTitle'){
    //   setEditRoom((prevEditRoom) => ({
    //     ...prevEditRoom,
    //     // [name]: typeof(value) === 'number' ? one+"|"+value : value+"|"+two,
    //     [name]:value
    //   }));
    // }

    setEditRoom((prevEditRoom) => ({
      ...prevEditRoom,
      [name]: type === "checkbox" ? checked : value,
    }));
    console.log("handle change connected");
  };
  console.log(editRoom);
  const handleImageRemove = (removeIndex) => {
    setSelectedFile(
      selectedFile.filter((file, index) => index !== removeIndex)
    );
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (selectedFile.length + files.length > 5) {
      toast.warn("Maximum 5 images are allowed");
      return;
    }
    setSelectedFile([...selectedFile, ...files]);
    setFileChange(true);
  };

  // form edit function
  const handleEditFormSubmit = async (e) => {
    e.preventDefault();
    console.log("edit form connected");

    try {
      const token = localStorage.getItem("token");
      let imagePaths = "";

      if (fileChange && selectedFile) {
        const imageFormData = new FormData();
        selectedFile.forEach((file, index) => {
          if (!(typeof file === "string")) {
            imageFormData.append(`image`, file);
          }
        });

        const imageUploadResponse = await axios.post(
          `${apiURL}/v1/files/multiple`,
          imageFormData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const imageDataArray = imageUploadResponse.data.body[0];
        imageDataArray.forEach((imageData, index) => {
          if (index === imageDataArray.length - 1) {
            imagePaths += imageData.filename;
          } else {
            imagePaths += imageData.filename + "|";
          }
        });

        imagePaths +=
          "|" +
          selectedFile
            .filter((imageName) => typeof imageName === "string")
            .join("|");
        imagePaths = imagePaths.trim();
      }

      const editFormData = new FormData();
      // Append to form data
      editFormData.append("files", imagePaths || selectedFile.join("|"));
      editFormData.append("credits", editRoom.roomCredit);
      editFormData.append(
        "title",
        editRoom.roomTitle + "|" + editRoom.roomCapacity
      );
      editFormData.append("facilities", newFacilities);
      editFormData.append("description", editRoom.description);
      editFormData.append("quickbook", editRoom.toggleQuickBooking);
      console.log("form data", imagePaths);
      const response = await axios.patch(
        `${apiURL}/v1/rooms/${roomId}`,
        editFormData, // Use editFormData here
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json",
          },
        }
      );
      navigate("/meetingrooms");
      console.log(response);
    } catch (error) {
      console.error("Error fetching edit data:", error);
    }
  };
  const {contextValue, setContextValue} = useContext(MyContext);
  return (
    <>
      {!isLoading && (
        <div className="navBarWrapper">
          <Sidebar />
          <div className="contentWrapper">
            <TopNavBar />
            {/* Content of the page */}
            <div className="addNewRoomWapper">
              <NavigateBack fill={"#66a3a6"} onClick={() => window.location.href="/meetingrooms"}/>
              <div className="addNewFormWrapper">
                <form
                  action=""
                  className="addNewRoomForm"
                  onSubmit={handleEditFormSubmit}
                >
                  <h2>Edit room</h2>
                  <AsteriskText />
                  <div
                    className="fileInput"
                    onDragOver={dragOverHandler}
                    onDrop={(event) => {
                      dropHandler(event, setSelectedFile, {
                        from: "rooms",
                        selectedFile: selectedFile,
                      });
                      setFileChange(true);
                    }}
                  >
                    <label htmlFor="files">Select Files</label>
                    {selectedFile.length > 0 && (
                      <ul className="imgNameList">
                        {selectedFile.map((file, index) => (
                          <li key={index}>
                            {file?.name || file}
                            <span
                              className="removeImage"
                              onClick={() => handleImageRemove(index)}
                            >
                              <CloseIcon />
                            </span>
                          </li>
                        ))}
                      </ul>
                    )}
                    <input
                      type="file"
                      name=""
                      id="files"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                      ref={fileInputRef}
                    />
                  </div>
                  <div className="addNewFormInputWrapper creditField">
                    <label htmlFor="roomCredit">Room Credits <Asterisk /></label>
                    <br />
                    <input
                      type="number"
                      name="roomCredit"
                      placeholder="Enter room credits"
                      onChange={handleEditInputChange}
                      value={editRoom.roomCredit}
                      onKeyDown={(e) => {
                        if (!(e.key === 'Backspace' || e.key === 'Delete' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Tab' || (e.key >= '0' && e.key <= '9'))) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  <div className="addNewFormInputWrapper">
                    <label htmlFor="roomTitle">Room Title<Asterisk /></label>
                    <br />
                    <input
                      type="text"
                      name="roomTitle"
                      placeholder="Enter room title"
                      onChange={handleEditInputChange}
                      value={editRoom.roomTitle}
                    />
                  </div>
                  {/* room capacity */}
                  <div className="addNewFormInputWrapper">
                    <label htmlFor="roomCapacity">Room Capacity<Asterisk /></label>
                    <br />
                    <input
                      type="number"
                      name="roomCapacity"
                      onChange={handleEditInputChange}
                      value={editRoom.roomCapacity}
                      onKeyDown={(e) => {
                        if (!(e.key === 'Backspace' || e.key === 'Delete' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Tab' || (e.key >= '0' && e.key <= '9'))) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  {/* room capacity code ends */}
                  <div className="addNewFormInputWrapper">
                    <label htmlFor="roomFacilities">Room Facilities<Asterisk /></label>
                    <br />
                    {facilities.length > 0 && (
                      <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        defaultValue={facilities.filter((facility, index) =>
                          currentFacilities.includes(facility.value)
                        )}
                        options={facilities}
                        onChange={handleEditInputChange}
                        name="roomFacilities"
                        // defaultValue={}
                        // menuPortalTarget={document.body}
                        // styles={{ menuPortal: base => ({ ...base, zIndex: 999 }) }}
                        className={`react-select-container ${contextValue ? "indexClass" : ""}`}
                        classNamePrefix="react-select"
                        classNames={{
                          container: (state) => "react-select-container",
                        }}
                        required
                      />
                    )}
                  </div>
                  <div className="addNewFormInputWrapper descheight">
                    <label htmlFor="description">Description<Asterisk /></label>
                    <br />
                    <textarea
                      type="text"
                      name="description"
                      placeholder="Enter Description"
                      onChange={handleEditInputChange}
                      value={editRoom.description}
                    ></textarea>
                  </div>
                  <div className="setQuickBookingSection">
                    <div className="leftClass">
                      <h4>Set Quick Bookings</h4>
                    </div>
                    <div className="rightClass">
                      <input
                        type="checkbox"
                        name="toggleQuickBooking"
                        id="toggleQuickBooking"
                        checked={editRoom.toggleQuickBooking}
                        onChange={handleEditInputChange}
                      />
                      <label htmlFor="toggleQuickBooking">
                        <div className="toggleText">
                          <span>On</span>
                          <span>OFF</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="addBtnWrapper">
                    <button type="submit">Add</button>
                  </div>
                </form>
              </div>
            </div>
            {/* Content of the page */}
          </div>
        </div>
      )}
    </>
  );
}

export default EditRoom;
