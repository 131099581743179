import React, { useContext, useState } from "react";
import "./LogOutWidget.css";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as SettingIcon } from "../../../src/assets/svg/settingIcon.svg";
import { ReactComponent as LogoutIcon } from "../../../src/assets/svg/LogoutIcon.svg";
import ProfileWidget from "./ProfileWidget";
import MyContext from "../../utils/MyContext";

function LogOutWidget({setIsProfileClicked}) {
  const [isAccountClicked, setIsAccountClicked] = useState(false);
 
  const navigate = useNavigate();

  const {contextValue, setContextValue} = useContext(MyContext);
  const handleAccountClicked = () => {
    console.log("clicked manage account");
    setIsAccountClicked(!isAccountClicked);
    setContextValue(true)
    
  };
  const handleLogOutClicked = () => {
    console.log("log out done");
    localStorage.removeItem("token");
    localStorage.removeItem("userData")
    localStorage.setItem("selectedLink", 1);
    window.location.href ="/";

  };

  const handleProfileModalClose = () =>{
    setIsAccountClicked(!isAccountClicked);
    setIsProfileClicked(false)
    setContextValue(false)
  }


  return (
    <>
      <div className="logOutWrapper">
          <div className="logoutSection">
          <Link to="">
          <div className="manageAccntDiv" onClick={handleAccountClicked}>
            
            <div className="settingIcon">
              <SettingIcon />
            </div>
            <div className="settingText">
              <h2>manage account</h2>
            </div>
          </div>
          </Link>
          </div>

          <div className="logoutSection  borderHide">
        <div className="logOutDiv" >
        <Link to="">
            <div className="manageLogoutDiv" onClick={handleLogOutClicked}>
            
            <div className="logoutIcon">
              <LogoutIcon />
            </div>
            <div className="logoutText">
              <h2>log out</h2>
            </div>
          </div>
            </Link>
        </div>
        </div>
        {isAccountClicked && <ProfileWidget setIsProfileClicked={setIsProfileClicked} onClose={() =>{handleProfileModalClose();  setContextValue(false); }}/>}
      </div>
    </>
  );
}

export default LogOutWidget;
