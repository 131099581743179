import { toast } from "react-toastify";

const dragOverHandler = (event)=>{
    event.preventDefault();
}

const dropHandler = (event,setSelectedFile,fromAndState)=>{
    event.preventDefault();
    if(fromAndState?.from === 'rooms'){

        const files = event.dataTransfer.files;
  
        if(fromAndState.selectedFile.length === 5){
            toast.warn("Maximum 5 images are allowed");
            return;
        }

        else{
            
            fromAndState.selectedFile.length+files.length>5 
            ?
            toast.warn("Maximum 5 images are allowed")
            :
            setSelectedFile((prevState)=>[...prevState,...files])
            console.log([...fromAndState.selectedFile,...files])
        }

    }
    else{
        setSelectedFile(event.dataTransfer.files["0"]);
    }
}

export {dragOverHandler,dropHandler}