import React, { useContext, useRef, useState } from "react";
import TopNavBar from "../../../components/TopNavBar";
import Sidebar from "../../../components/Sidebar";
import { useNavigate } from "react-router";
import axios from "axios";
import "./AddNewCoache.css";
import {
  dragOverHandler,
  dropHandler,
} from "../../../utils/imageDragDropHandlers";
import { QueryClient, useQuery, useQueryClient } from "react-query";
import { fetchFilters } from "../../../utils/filtersOperations";
import { ReactComponent as CloseIcon } from "../../../assets/svg/closeImg.svg";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import MyContext from "../../../utils/MyContext";
import { toast } from "react-toastify";
import { Asterisk, AsteriskText } from "../../../utils/Asterisk";
import NavigateBack from "../../../utils/NavigateBack";
const apiURL = process.env.REACT_APP_API_URL;

function AddNewCoache() {
  const filtersEndpoint = "skills";
  const [selectedFile, setSelectedFile] = useState(null);
  const [newCoachData, setNewCoachData] = useState({});
  const [skills, setSkills] = useState([]);
  const fileInputRef = useRef(null);
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery(
    ["skillsFilters", filtersEndpoint],
    () => fetchFilters(filtersEndpoint),
    {
      onSuccess: (data) => {
        setSkills(data.map((skills) => skills.title));
      },
    }
  );
  const navigate = useNavigate();
  const animatedComponents = makeAnimated();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log(file);
    setSelectedFile(file);
  };
let debounceTimer;

const handleOnInputChange = async (input, additionalDetails) => {
  // Handle skills field
  if (additionalDetails?.name === "skills") {
    const skill = input.map((facility) => facility.value);
    setNewCoachData({
      ...newCoachData,
      [additionalDetails.name]: skill.join("|"),
    });
    return;
  }

  const { name, value } = input.target;

  // Update form state
  setNewCoachData({ ...newCoachData, [name]: value });

  // Email validation logic
  if (name === "email") {
    clearTimeout(debounceTimer); // Clear the debounce timer
    debounceTimer = setTimeout(async () => {
      try {
        if (!value.trim()) {
          toast.warn("Email cannot be empty");
          return;
        }

        const token = localStorage.getItem("token");
        const emailCheckResponse = await axios.get(`${apiURL}/v1/users/check-email`, {
          params: { email: value.trim() },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (emailCheckResponse.data.exists) {
          toast.error("Email is already registered under another role.");
          setNewCoachData((prev) => ({
            ...prev,
            email: "",
          }));
        }
      } catch (error) {
        console.error("Error checking email:", error.message);
      }
    }, 300);
  }
};

  const handleImageRemove = () => {
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleNewCoachAdd = async (e) => {
    console.log("handleCoachAdd Entered");
    e.preventDefault();
    if (!selectedFile) {
      toast.error("Please select an image");
      return;
    }
    const _email = newCoachData?.email;
    const trimmedEmail = String(_email)?.trim();
    if (trimmedEmail.length === 0) {
      toast.warn("Please Enter Email");
      return;
    }
    const emailCheckResponse = await axios.get(
      `${apiURL}/v1/users/check-email`,
      {
        params: { email: trimmedEmail },
      }
    );
    if (emailCheckResponse.data.exists) {
      toast.error("Email is already registered under another role.");
      return;
    }
    try {
      const token = localStorage.getItem("token");
      const imageFormData = new FormData();
      imageFormData.append("file", selectedFile);

      const imageUploadResponse = await axios.post(
        `${apiURL}/v1/files/`,
        imageFormData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("add coach file upload", imageUploadResponse.status);
      const imagePath = imageUploadResponse.data.body[0].filename;
      const formData = new FormData();
      console.log(newCoachData);
      formData.append("files", imagePath);
      formData.append("title", newCoachData.coachName);
      formData.append("skills", newCoachData.skills);
      formData.append("description", newCoachData.description);
      formData.append("email", newCoachData.email);
      const response = await axios.post(`${apiURL}/v1/coaches`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
      });
      queryClient.invalidateQueries(["Coaches"]);
      navigate("/coaches");
      console.log("success:", response.data);
    } catch (error) {
      console.log(error.message);
    }
  };
  const { contextValue, setContextValue } = useContext(MyContext);
  return (
    <>
      <div className="navBarWrapper">
        <Sidebar />
        <div className="contentWrapper">
          <TopNavBar />
          {/* Content of the page */}
          <div className="addNewRoomWapper">
            <NavigateBack
              fill={"#66a3a6"}
              onClick={() => (window.location.href = "/coaches")}
            />

            <div className="addNewFormWrapper">
              <form
                action=""
                className="addNewRoomForm"
                onSubmit={handleNewCoachAdd}
              >
                <h2>Add new coach</h2>
                <AsteriskText />
                <div
                  className="fileInput"
                  onDragOver={dragOverHandler}
                  onDrop={(event) => {
                    dropHandler(event, setSelectedFile);
                  }}
                >
                  <label htmlFor="files">Select File</label>
                  <div
                    className="fileNames"
                    style={{ display: selectedFile ? "flex" : "none" }}
                  >
                    <p
                      className={
                        selectedFile ? "showFileDetails" : "hideFileDetails"
                      }
                    >
                      {selectedFile ? (
                        <>
                          {selectedFile.name}
                          <span
                            className="removeImage"
                            onClick={handleImageRemove}
                          >
                            <CloseIcon />
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                  {/* <p className={selectedFile ? "showFileDetails" : "hideFileDetails"}>{selectedFile ? selectedFile.name : ""}</p> */}
                  <input
                    type="file"
                    name=""
                    id="files"
                    style={{ display: "none" }}
                    accept="image/png, image/jpeg"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                  />
                  {/* {selectedFile && <span className="removeImage" style={{border:'solid'}} onClick={()=>{setSelectedFile(null)}}>X</span>} */}
                </div>
                <div className="addNewFormInputWrapper">
                  <label htmlFor="coachName">
                    <span>Email</span>
                    <span style={{ color: "red", paddingLeft: "4px" }}>*</span>
                  </label>
                  <br />
                  <input
                    type="text"
                    name="email"
                    placeholder="Enter your Email"
                    onChange={handleOnInputChange}
                    onBlur={handleOnInputChange}
                    required
                  />
                </div>
                <div className="addNewFormInputWrapper">
                  <label htmlFor="coachName">
                    Coach Name <Asterisk />
                  </label>
                  <br />
                  <input
                    type="text"
                    name="coachName"
                    placeholder="Enter Coach Name"
                    onChange={handleOnInputChange}
                    required
                  />
                </div>

                {/* <div className="addNewFormInputWrapper creditField">
                  <label htmlFor="credits">
                    Credits <Asterisk />
                  </label>
                  <br />
                  <input
                    type="number"
                    name="credits"
                    placeholder="Enter Credits"
                    onChange={handleOnInputChange}
                    onKeyDown={(e) => {
                      if (
                        !(
                          e.key === "Backspace" ||
                          e.key === "Delete" ||
                          e.key === "ArrowLeft" ||
                          e.key === "ArrowRight" ||
                          e.key === "Tab" ||
                          (e.key >= "0" && e.key <= "9")
                        )
                      ) {
                        e.preventDefault();
                      }
                    }}
                    required
                  />
                </div> */}
                {/* select */}
                {!isLoading && (
                  <div className="addNewFormInputWrapper">
                    <label htmlFor="skills">
                      Skills <Asterisk />
                    </label>
                    <br />
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      options={skills.map((skill) => {
                        return { value: skill, label: skill };
                      })}
                      onChange={handleOnInputChange}
                      name="skills"
                      // menuPortalTarget={document.body}
                      // styles={{ menuPortal: base => ({ ...base, zIndex: 999 }) }}
                      className={`react-select-container ${
                        contextValue ? "indexClass" : ""
                      }`}
                      classNamePrefix="react-select"
                      classNames={{
                        container: (state) => "react-select-container",
                      }}
                      required
                    />
                  </div>
                )}
                <div className="addNewFormInputWrapper descheight">
                  <label htmlFor="description">
                    Description <Asterisk />
                  </label>
                  <br />
                  <textarea
                    type="text"
                    name="description"
                    placeholder="Enter Description"
                    onChange={handleOnInputChange}
                    required
                  ></textarea>
                </div>
                <div className="addBtnWrapper">
                  <button type="submit">Add</button>
                </div>
              </form>
            </div>
          </div>
          {/* Content of the page */}
        </div>
      </div>
    </>
  );
}

export default AddNewCoache;
