// UpdateMembership.js
import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Snackbar,
  Alert,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";

const UpdateMembership = ({
  open,
  user,
  onHide,
  membershipOptions,
  onMembershipUpdate,
  refetchUsers,
  closeUserDetails,
}) => {
  const [selectedMembership, setSelectedMembership] = useState(user.membership);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  console.log("user", user);
  const handleSubmit = () => {
    // onSubmit(user.id, selectedMembership);
    handleUpdate();
    onHide();
  };

  const handleUpdate = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/v1/users/${user.id}`,
        {
          membership: selectedMembership, // Send selected membership
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the JWT token
          },
        }
      );
      console.log("response", response.status);
      setSnackbarMessage("Membership updated successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      if (response.status === 200) {
        refetchUsers();
        closeUserDetails();
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to update membership.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  console.log("sleected", selectedMembership);

  return (
    <Dialog open={open} onClose={onHide} fullWidth maxWidth="sm">
      <DialogTitle>Update Membership</DialogTitle>
      <DialogContent>
        <TextField
          label="Current Membership"
          value={user.membership} // Display the current membership
          fullWidth
          margin="dense"
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          select
          label="Select Membership"
          value={selectedMembership}
          onChange={(e) => setSelectedMembership(e.target.value)}
          fullWidth
          margin="dense"
        >
          {membershipOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={onHide} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateMembership;
