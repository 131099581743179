import axios from "axios";
const apiURL = process.env.REACT_APP_API_URL;

const fetchAllData = async (endpoint) =>{
  
    try {
      const token = localStorage.getItem("token");
  
      //Fetching data
      console.log("link",`${apiURL}${endpoint}`)
      const response = await axios.get(`${apiURL}${endpoint}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      const data = response.data.body[0];

      if(endpoint.includes('/settings') || endpoint.includes('meetingbookings') || endpoint.includes('coachbookings') || endpoint.includes('allusers')){
        console.log("it is in if")
        return {data: response.data.body[0], status:response.status};
      }
    

      if(endpoint.includes("/rooms")){
        
        for(let i = 0; i<data.length;i++){
          
          const imageRequests = data[i].files.split("|").map((imageName,index) => 
          axios.get(`${apiURL}/v1/files/${imageName}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            responseType: 'arraybuffer',
          })
          );
          
          const imageResponses = await Promise.all(imageRequests);
          const imageLocations = [];
          imageResponses.map((imageresponse,index)=>{
            const blob = new Blob([imageresponse.data],{ type: 'image/jpeg' })
            const url = URL.createObjectURL(blob)
            imageLocations.push(url);
          })
          data[i].imageUrl=imageLocations;
        }


      }

      else{
          //Fetching images
      
          const imageRequests = data.map((item) => 
          axios.get(`${apiURL}/v1/files/${item?.image || item.files}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            responseType: 'arraybuffer',
          })
          );
        const imageResponses = await Promise.all(imageRequests);

        imageResponses.map((imageresponse,index)=>{
          const blob = new Blob([imageresponse.data],{ type: 'image/jpeg' })
          const url = URL.createObjectURL(blob)
          data[index].imageUrl=url;
        })
      }

  
      return data;
  
    } catch (error) {
      console.error(`Error fetching ${endpoint} data: fetchall`, error);
    }
  }


  
  const fetchSingleData = async (endpoint) =>{
  
    try {
      const token = localStorage.getItem("token");
  
      //Fetching data
      console.log("link",`${apiURL}${endpoint}`)
      const response = await axios.get(`${apiURL}${endpoint}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      const data = response.data.body[0];
      console.log(" meeting data",data)
  
      //Fetching image

      let imageResponse = ""
      if(data?.files.includes("|")){

        imageResponse = await axios.get(`${apiURL}/v1/files/${data.files.split("|")[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'arraybuffer',
        })
      }

      else{
        imageResponse = await axios.get(`${apiURL}/v1/files/${data?.image || data.files}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'arraybuffer',
        })
      }

        const blob = new Blob([imageResponse.data],{ type: 'image/jpeg' })
        const url = URL.createObjectURL(blob)
        console.log("regex",url)
        data.imageUrl=url;

      return data;
  
    } catch (error) {
      console.error(`Error fetching ${endpoint} data:fetchsingle`, error);
    }
  }


  const fetchUserDetails = async () =>{
    try {
      const token = localStorage.getItem("token");
      const { data } = await axios.get(`${apiURL}/v1/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const userData = data.body[0]

      if(userData.image.includes('http')){

        // const imageResponse = await axios.get(`${userData.image}`,
        // {
        //   responseType: 'arraybuffer',
        // });

        // const blob = new Blob([imageResponse.data],{ type: 'image/jpeg' })
        // const url = URL.createObjectURL(blob)
        userData.imageUrl=userData.image;

      }

      else{
        const imageResponse = await axios.get(`${apiURL}/v1/files/${userData.image}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'arraybuffer',
        });

        const blob = new Blob([imageResponse.data],{ type: 'image/jpeg' })
        const url = URL.createObjectURL(blob)
        userData.imageUrl=url;
      }


      return userData;
    } catch (error) {
      console.log(error);
    }
  }


  export  {fetchAllData,fetchSingleData,fetchUserDetails};