import axios from "axios";
import { toast } from "react-toastify";
const apiURL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("token");
const path = "/v1/filter";


const createFilter = async(endpoint,title) => {
    
    try {
        
        const filterBody = new FormData();

        filterBody.append("title",title);

        const response = await axios.post(
        `${apiURL}${path}${endpoint}`,
        filterBody,
        {
            headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json",
            },
        }

        );
        console.log("here",response.data.body[0])
        
        return response.data.body[0]
        
    } catch (error) {
      console.log(`Error creating filter ${error.message}`);
    }
}


const fetchFilters = async(endpoint) =>{
    const token = localStorage.getItem("token");
    try{
        const response = await axios.get(
            `${apiURL}${path}${endpoint}`,
            {
                headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json",
                },
            }
        )
        
        return response.data.body[0];
    }catch(error){
        console.log(`Error fetching ${endpoint}`,error.message);
    }
}


const updateFilters = async(endpoint,id,operation,title) =>{
    const token = localStorage.getItem("token");
    try{

        if(operation === "patch"){
            console.log("url: "+`${apiURL}${path}${endpoint}/${id}`)
            const filterBody = new FormData();
            filterBody.append("title",title)
            const response = await axios[operation](
                `${apiURL}${path}${endpoint}/${id}`,
                filterBody,
                {
                    headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-type": "application/json",
                    },
                }
            )
            
            return response;
        }

        else if(operation ==='delete'){
            const response = await axios[operation](
                `${apiURL}${path}${endpoint}/${id}`,
                // filterBody,
                {
                    headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-type": "application/json",
                    },
                });
            return response;
        }
        
    }catch(error){
        console.log(`Error updating ${endpoint}`,error.message);
    }
}


const alertHandler = (response,endpoint,id,requestType) =>{

    if(response.statusText === 'OK'){
        toast.success("Filter "+(requestType === 'delete' ? 'Deleted Successfully!' : 'Edited Successfully!'));
       }
    
    else{
    toast.error(`failed to `+(requestType === 'delete' ? 'delete' : 'edit')+` ${endpoint} id:${id}`)
    }
}


const deleteFilterHandler = async (filterState,setFiltersState,Index,endpoint) =>{
    const token = localStorage.getItem("token");
        try{
            const requestType = 'delete'
            const targetedFilter = filterState[Index];
            console.log(filterState[Index])
            const filerId = targetedFilter.id;

            setFiltersState(filterState.filter((filter,index)=>index!==Index))

            const response = await updateFilters(endpoint,filerId,requestType,null);

            alertHandler(response,endpoint,filerId,requestType);

        }catch(error){
            console.log(`Error deleting ${endpoint} filter`,error.message);
            toast.error(`There was an error while deleting ${endpoint} filter`);
        }

}


const editFilterHandler = async (setfilterPanelOpen,filterPanelOpen,filterState,setFiltersState,index,inputValue,setInputValue,endpoint)=>{

    const token = localStorage.getItem("token");
    if(filterPanelOpen === 'edit'){
        
        const index = filterState.findIndex(obj=>obj.id === inputValue.id);
        
        const response = await updateFilters(endpoint,inputValue.id,'patch',inputValue.name);
        // console.log("response",response)

        alertHandler(response,endpoint,inputValue.id);

        const copyFilterState =  [...filterState];
        copyFilterState[index].name = inputValue.name

        response.statusText === 'OK' ? setFiltersState(copyFilterState) : setFiltersState(filterState);

        setfilterPanelOpen(false);
    }

    else{
        const targetedFilter = filterState[index];
        setfilterPanelOpen("edit");
        setInputValue(targetedFilter);
    }

}

export {
    createFilter,
    fetchFilters,
    updateFilters,
    deleteFilterHandler,
    editFilterHandler
}