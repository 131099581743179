import React, { useState } from "react";
import { createFilter, fetchFilters,deleteFilterHandler,editFilterHandler } from '../../../utils/filtersOperations';
import { useQuery } from "react-query";
import LoadingOverlay from 'react-loading-overlay-ts';
import "./FiltersMeetingRoom.css";
import { toast } from "react-toastify";
function FiltersMeetingRoom() {
  const facilities = "facilities";
  const [filterPanelOpen, setfilterPanelOpen] = useState(false);
  const [roomFacilities, setRoomFacilities] = useState([]);
  const [newFacility, setNewFacility] = useState({name:""});
  const { data, isLoading } = useQuery(['facilities',facilities],
  ()=>fetchFilters(facilities),
  {onSuccess:(data)=>{
    setRoomFacilities(data.map((obj,index)=>{return {id:obj.id,name:obj.title}}))
    console.log(data)
  }})

  // filter panel 
  const viewHandle = () => {
    setfilterPanelOpen(true);
    setNewFacility({name:""});
  };
  
  const getRandomId = () =>{
    return Math.floor(Math.random() * 10000);
  }

  const filterChange = (e) =>{
    setNewFacility({...newFacility, name: e.target.value });
  }

  const saveFilterHandle = async (e,filterPanelOpen)=>{
    console.log(newFacility)
    if (!(newFacility?.name) && !(newFacility.name.trim())) {
      toast.warn("Please enter the details!");
      return;
    }
    if(filterPanelOpen === 'edit'){
      await editFilterHandler(setfilterPanelOpen,filterPanelOpen,roomFacilities,setRoomFacilities,"",newFacility,setNewFacility,facilities);
      return
    }
    if (newFacility.name.trim() !== "") {

      const {id} = await createFilter(facilities,newFacility.name)

      setRoomFacilities([
        ...roomFacilities,
        { id: id, name: newFacility.name },
      ]);
      // setNewEmpDesig("");
      
      setfilterPanelOpen(false);

    }
  }
  return (
    <>
    <LoadingOverlay
        active={isLoading}
        spinner
        text='Loading your content...'
        className="mobLoader"
      >
      {/* screens page, table of screens */}
      <div className="tableContainer">
        <table className="screensTable">
          <thead>
            <tr>
              <th>Sr.no</th>
             
              <th>Field name</th>
              <th>Actions</th>  
            </tr>
          </thead>
          <tbody>
            {roomFacilities.map((facility, index) => (
              <tr key={facility.id}>
                <td>{index + 1}</td>
               
                <td>{facility.name}</td>
                <td>
                  <div className="filterBtnClass">
                  <button onClick={()=>editFilterHandler(setfilterPanelOpen,filterPanelOpen,roomFacilities,setRoomFacilities,index,newFacility,setNewFacility,facilities)}>Edit</button>
                  <button onClick={()=>deleteFilterHandler(roomFacilities,setRoomFacilities,index,facilities)}>Delete</button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* add new filter panel  */}
      {filterPanelOpen && (
        <div className="addFilterOverlay">
          <div className="filterTableContentWrapper">
            <div className="addNewFilterHeading">
              <h2>add new filters</h2>
            </div>
            <div className="filterInputsWrapper">
              <h2>Add room facility</h2>
              <div className="addfilterInputWrapper">
             <label htmlFor="roomFacilities">Room Facilities:</label>
             <input type="text" name="roomFacilities" value={newFacility.name} onChange={filterChange}/>
             </div>
              <button
                className="filterOverlaySaveBtn"
                onClick={(event) => {
                  saveFilterHandle(event,filterPanelOpen);
                  setfilterPanelOpen(false);
                }}
              >
                Save
              </button>
              <button
                className="filterOverlaySaveBtn cancelBtn"
                onClick={(event) => {
                  setfilterPanelOpen(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="filterBtnWrapper">
        <button onClick={viewHandle}>Add Filter</button>
      </div>
      </LoadingOverlay>
    </>
  );
}

export default FiltersMeetingRoom;
