import React, { useEffect, useState } from "react";
import "./Filters.css";
import FiltersMeetingRoom from "./FiltersMeetingRoom";
import FiltersCoachesScreen from "./FiltersCoachesScreen";
import FiltersStaffScreen from "./FiltersStaffScreen";
function Filters() {
  const [filterActiveSection, setFilterActiveSection] = useState(1);
  const coachesSection = () => {
    setFilterActiveSection(1);
  };
  const meetingRoomsSection = () => {
    setFilterActiveSection(2);
  };
  const staffSection = () => {
    setFilterActiveSection(3);
  };


  return (
    <>
      <div className="filterSubHeading">
        <div
          className={`filterSubSection ${
            filterActiveSection === 1 ? "active" : ""
          }`}
          onClick={coachesSection}
          id="addCoachSkillsId"
        >
          <h2>Coaches</h2>
        </div>
        <div
          className={`filterSubSection ${
            filterActiveSection === 2 ? "active" : ""
          }`}
          onClick={meetingRoomsSection}
          id="addRoomfacilitiesId"
        >
          <h2>Meeting Rooms</h2>
        </div>
        {/* staff room headiing */}
        <div
          className={`filterSubSection ${
            filterActiveSection === 3 ? "active" : ""
          }`}
          onClick={staffSection}
          id="addEmployeedesignationId"
        >
          <h2>Staff</h2>
        </div>
      </div>
      {/*  */}
      {filterActiveSection === 1 ? (
        <FiltersCoachesScreen />
      ) : filterActiveSection ===2 ?(
        <FiltersMeetingRoom />
      ):(<FiltersStaffScreen/>)}
    </>
  );
}

export default Filters;
