import React, { useState } from 'react';
import { createFilter, fetchFilters,deleteFilterHandler,editFilterHandler } from '../../../utils/filtersOperations';
import { useQuery } from "react-query";
import LoadingOverlay from 'react-loading-overlay-ts';
import { toast } from 'react-toastify';

function FiltersStaffScreen() {
  const designations = "designations";
  const [filterPanelOpen, setfilterPanelOpen] = useState(false);
  const [empDesignation, setEmpDesignation] = useState([]);
  const [inCharge, setInCharge] = useState("");
  const [newEmpDesig, setNewEmpDesig] = useState({name:""});
  const { data, isLoading } = useQuery(['designations',designations],
    ()=>fetchFilters(designations),
    {onSuccess:(data)=>{
      setEmpDesignation(data.map((obj,index)=>{return {id:obj.id,name:obj.title}}))
      console.log(data)
    }})

  const viewHandle = () => {
    setfilterPanelOpen(true);
    setNewEmpDesig({name:""});
  };
  const getRandomId = () =>{
    return Math.floor(Math.random() * 10000);
  }
  const empDesigChange = (e) =>{
      setNewEmpDesig({...newEmpDesig, name: e.target.value });
  }
 
  const saveFilterHandle = async (e,filterPanelOpen) =>{
    if (!(newEmpDesig?.name) && !(newEmpDesig.name.trim())) {
      toast.error("Please enter the details!");
      return;
    }
    if(filterPanelOpen === 'edit'){
      await editFilterHandler(setfilterPanelOpen,filterPanelOpen,empDesignation,setEmpDesignation,"",newEmpDesig,setNewEmpDesig,designations);
      return
    }

    if (newEmpDesig.name.trim() !== "") {

      const {id} = await createFilter(designations,newEmpDesig.name)

      setEmpDesignation([
        ...empDesignation,
        { id: id, name: newEmpDesig.name },
      ]);
      // setNewEmpDesig("");
      
      setfilterPanelOpen(false);

    }
  }


  return (
    <>
     <LoadingOverlay
        active={isLoading}
        spinner
        text='Loading your content...'
        className="mobLoader"
      >
    <div className="tableContainer">
      <table className='screensTable'>
        <thead>
          <tr>
            <th>Sr.no</th>
           
            <th>Field name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
            {empDesignation.map((emp, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                
                <td>{emp.name}</td>
                <td>
                  <div className="filterBtnClass">
                    <button onClick={()=>editFilterHandler(setfilterPanelOpen,filterPanelOpen,empDesignation,setEmpDesignation,index,newEmpDesig,setNewEmpDesig,designations)}>Edit</button>
                    <button onClick={()=>deleteFilterHandler(empDesignation,setEmpDesignation,index,designations)}>Delete</button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
      </table>
    </div>
    {/* overlay panel */}
    {filterPanelOpen && (
      <div className="addFilterOverlay">
        <div className="filterTableContentWrapper">
          <div className="addNewFilterHeading">
            <h2>add new filters</h2>
          </div>
          {/* inputs for adding filters */}
          <div className="filterInputsWrapper">
            <h2>Add staff filters</h2>
            <div className="addfilterInputWrapper">
              <label htmlFor="empDesignation">Employee Designation:</label>
              <input type="text" name="empDesignation" value={newEmpDesig.name} onChange={empDesigChange}/>
            </div>
            {/* <div className="addfilterInputWrapper">
              <label htmlFor="inCharge">In-Charge:</label>
              <input type="text" name="inCharge" value={inCharge} onChange={inChargeChange}/>
            </div> */}
            <button
                className="filterOverlaySaveBtn"
                onClick={(event) => {
                  saveFilterHandle(event,filterPanelOpen);
                  setfilterPanelOpen(false);
                }}
              >
                Save
              </button>
              <button
                className="filterOverlaySaveBtn cancelBtn"
                onClick={(event) => {
                  setfilterPanelOpen(false);
                }}
              >
                Cancel
              </button>
          </div>
        </div>
      </div>
    )}
    <div className="filterBtnWrapper">
        <button  onClick={viewHandle}>Add Filter</button>
      </div>
      </LoadingOverlay>
    </>
  )
}

export default FiltersStaffScreen