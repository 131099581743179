import { useState } from "react";
// import "./BookingHistory.css";
// import RoomBookingHistory from "./room/RoomBookingHistory";
// import CoachBookingHistory from "./coach/CoachBookingHistory";
import Sidebar from "../../components/Sidebar";
import TopNavBar from "../../components/TopNavBar";
import RoomReviewMod from "./meetingRoom/RoomReviewMod";
import CoachReviewMod from "./coach/coachReviewMod";

const ReviewMod = () => {
  const [roomReviewOpen, setRoomReviewOpen] = useState(true); // Default to Room Booking History
  const [coachReviewOpen, setCoachReviewOpen] = useState(false); // Default Coach to closed

  const handleRoomReviewClick = () => {
    setRoomReviewOpen(true);
    setCoachReviewOpen(false);
  };

  const handleCoachReviewClick = () => {
    setRoomReviewOpen(false);
    setCoachReviewOpen(true);
  };

  return (
    <div className="navBarWrapper">
      <Sidebar />
      <div className="contentWrapper">
        <TopNavBar />
        <div className="bookingRequestWrapper">
          <div className="sortButtons">
            <button
              onClick={handleRoomReviewClick}
              className={roomReviewOpen ? "selectedButton" : ""}
            >
              Room Reviews
            </button>
            <button
              onClick={handleCoachReviewClick}
              className={coachReviewOpen ? "selectedButton" : ""}
            >
              Coach Reviews
            </button>
          </div>
          {roomReviewOpen ? <RoomReviewMod /> : <CoachReviewMod />}
        </div>
      </div>
    </div>
  );
};

export default ReviewMod;
