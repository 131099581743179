import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "./RoomReviewMod.css";
import ToggleSwitch from "../../../components/UIComponent/ToggleSwitch";
import RoomReviewRow from "./RoomReviewRow";

const RoomReviewMod = () => {
  const [reviewData, setReviewData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllReviewsData();
  }, []);

  const getAllReviewsData = async () => {
    try {
      setLoading(true)
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/reviews/getAll`
      );
      const fetchedData = response.data.body[0];
      console.log("fetchedData", fetchedData);
      setReviewData(fetchedData);
    } catch (error) {
      console.error(error.message);
    } finally{
      setLoading(false)
    }
  };

  const handleToggle = async (index) => {
    const review = reviewData[index];
    const updatedIsDisabled = !review.isDisabled; // Get the new isDisabled value

    try {
      setLoading(true);
      // Update the review visibility via the API
      await axios.put(
        `${process.env.REACT_APP_API_URL}/v1/reviews/updateVisibility/${review.id}`,
        {
          isDisabled: updatedIsDisabled,
        }
      );

      // Update local state with the new visibility value
      setReviewData((prevData) => {
        const newData = [...prevData];
        newData[index] = { ...newData[index], isDisabled: updatedIsDisabled };
        return newData;
      });

      // Optionally reload all reviews if you want the latest state from the server
      // await getAllReviewsData();
    } catch (error) {
      console.error(error.message);
      toast.error("Error updating review visibility:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const toggleVisibility = (isDisabled) =>
    isDisabled ? "Disabled" : "Visible";

  console.log("Review Data", reviewData, typeof reviewData);

  return (
    <>
    {reviewData?.length > 0 ? (
      <div className="bookingReqTableContainer">
        <table className="bookingRequestTable" id="bookingDetailsId">
          <thead>
            <tr>
              <th>Room Name</th>
              <th>Date</th>
              <th>Check-in Time</th>
              <th>Duration</th>
              <th>Rating</th>
              <th>Comment</th>
              {/* <th>Status</th> */}
              <th>Reviewed</th>
              <th>Disable</th>
            </tr>
          </thead>
          <tbody>
          {reviewData.map((item, index) => (
            <RoomReviewRow
              key={index} // key prop for the mapped component
              item={item}
              refreshData={getAllReviewsData} // Pass the refresh function
            />
          ))}
          </tbody>
        </table>
      </div>
    ) : (
      <p>No bookings found for review Moderation</p>
    )}
      
    </>
  );
};

export default RoomReviewMod;
