import axios from "axios";
import { fetchAllData } from "./fetchData";
const apiURL = process.env.REACT_APP_API_URL;



const attachImagesToData = async (data,bookingRequestEndpoint) =>{

    let endpoint  = "";
    if(bookingRequestEndpoint.includes('meetingbookings')){
        endpoint = "/v1/rooms";
    }

    else{
        endpoint = '/v1/coaches';
    }

    const getAllData = await fetchAllData(endpoint);

    
    for(let i = 0; i < data.length; i++){
        
        for(let j = 0; j < getAllData.length; j++){
            console.log("getAllData",getAllData)
            if(data[i]?.bookedRoomId=== getAllData[j].id || data[i].bookedCoachId === getAllData[j].id){

                if(getAllData[j].imageUrl?.length){
                    // data[i].imageUrl = getAllData[j].imageUrl;

                    if(bookingRequestEndpoint.includes('meetingbookings')){
                        data[i].imageUrl = getAllData[j].imageUrl[0];
                    }
                
                    else{
                        data[i].imageUrl = getAllData[j].imageUrl;
                    }
    
                }
            }
        }
    }

    console.log("done",data)

    return data;

}


export  {attachImagesToData};