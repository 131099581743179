import React, { useEffect, useState, useRef } from "react";
import { useQuery } from "react-query";
import "./UiSettings.css";
import uploadImgIcon from "../../../../src/assets/png/Capa_1.png";
import {
  getUserSpecificSettings,
  createSetting,
  updateSetting,
} from "../../../utils/uiSettingsOperations";
import {
  dropHandler,
  dragOverHandler,
} from "../../../utils/imageDragDropHandlers";
import { ReactComponent as CloseIcon } from "../../../assets/svg/closeImg.svg";
import { toast } from "react-toastify";

function UiSettings() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedImageSrc, setUploadedImageSrc] = useState(null);
  const fileInputRef = useRef();
  const [settings, setSettings] = useState({
    appname: "incubator",
    quickbook: false,
    description: "",
    image: "",
  });
  const [companyDescription, setcompanyDescription] = useState("");
  const { data, isLoading } = useQuery({
    queryKey: "settings",
    queryFn: getUserSpecificSettings,
    onSuccess: (data) => {
      if (data?.length && !selectedFile) {
        console.log("data[0]", data[0]);
        setSettings({ ...data[0] });
        setSelectedFile(data[0]?.image || "");
        setUploadedImageSrc(data[0].imageUrl);
      }
    },
  });

  console.log("dataC", data)

  console.log("selectedFile", selectedFile);
  console.log("uploadedImageSrc", uploadedImageSrc);
  getUserSpecificSettings();
  console.log("settings data:", settings);

  const handleFileChange = (event, isFromOnDrop) => {
    let file = null;

    if (isFromOnDrop) {
      file = event.dataTransfer.files[0];
    } else {
      file = event.target.files[0];
    }

    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setUploadedImageSrc(imageUrl);
      setSelectedFile(file);
    }
  };

  const onInputChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "quickbook") {
      setSettings((prevstate) => {
        return { ...prevstate, [name]: checked };
      });
    } else {
      setSettings((prevstate) => {
        return { ...prevstate, [name]: value };
      });
    }
  };

  const handleImageRemove = () => {
    setSelectedFile(null);
    setUploadedImageSrc(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    console.log("data", data)
    try {
      if (data?.length) {
        await updateSetting(settings.id, settings, selectedFile);
        console.log("data", data);
        toast.success("Updated Successfully!");
        window.location.reload();
      } else {
        await createSetting(settings, selectedFile);
        // toast.success("No data toast");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      toast.error("There was an error while saving settings");
    }
  };

  // const handleOnSubmit = async (e) => {
  //   e.preventDefault();

  //   const formData = new FormData();
  //   formData.append("appname",  "incubator");
  //   formData.append("quickbook",  false);
  //   formData.append("description", "incubator");

  //   if (selectedFile) {
  //     formData.append("image", selectedFile);
  //   }

  //   try {
  //     console.log("Submitting formData", formData);
  //     if (data?.length) {
  //       await updateSetting(settings.id, formData);
  //     } else {
  //       await createSetting(formData);
  //     }
  //     toast.success("Settings Saved Successfully!");
  //   } catch (error) {
  //     console.log("Error while saving settings:", error);
  //     toast.error("There was an error while saving settings");
  //   }
  // };

  return (
    <>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <form action="" onSubmit={handleOnSubmit} className="flexForm">
          <div
            className="uploadImgSection"
            id="uploadNewLogoId"
            onDrop={(event) => {
              dropHandler(event, setSelectedFile);
              handleFileChange(event, true);
            }}
            onDragOver={dragOverHandler}
          >
            <div className="uploadImgIconWrapper">
              {uploadedImageSrc ? (
                <img src={uploadedImageSrc} alt="" />
              ) : (
                <img src={uploadImgIcon} alt="" />
              )}
            </div>
            <label htmlFor="files">Upload Image</label>
            <p className={selectedFile ? "showFileDetails" : "hideFileDetails"}>
              {selectedFile ? (
                <>
                  {selectedFile?.name || selectedFile}
                  <span className="removeImage" onClick={handleImageRemove}>
                    <CloseIcon />
                  </span>
                </>
              ) : (
                ""
              )}
            </p>
            <input
              type="file"
              name="image"
              id="files"
              style={{ display: "none" }}
              accept="image/png, image/jpeg"
              onChange={handleFileChange}
              ref={fileInputRef}
            />
          </div>
          <div className="logoTxtClass">
            <input
              name="appname"
              type="text"
              value={settings.appname}
              onChange={onInputChange}
              id="addEditCompanyNameId"
              required
            />
            {/* <h2>incubator</h2> */}
          </div>
          {/* description input */}
          <div className="inputTxtClass">
            <textarea
              name="description"
              type="text"
              id="addEditDescriptionId"
              value={settings.description}
              onChange={onInputChange}
              placeholder="Enter your description here"
            ></textarea>
          </div>
          {/* allow quick booking section */}
          <div className="allowQuickBookingSection" id="allowQuickBookingId">
            <div className="leftTxtClass">
              <h4>Allow Quick Bookings</h4>
            </div>
            <div className="rightCheckboxClass">
              <input
                type="checkbox"
                name="quickbook"
                id="toggleAllowBooking"
                onChange={onInputChange}
                checked={settings.quickbook}
              />
              <label htmlFor="toggleAllowBooking">
                <div className="toggleAllowText">
                  <span>ON</span>
                  <span>OFF</span>
                </div>
              </label>
            </div>
          </div>
          <div className="quickbkngBtnWrapper">
            <button type="submit">Save</button>
          </div>
        </form>
      )}
    </>
  );
}

export default UiSettings;
