import React, { useState } from "react";
import ActionsIcon from "../../assets/svg/kebab.svg"; // Adjust the import based on your SVG location
import ActionMenu from "./ActionMenu"; // Adjust the import path as needed
import UpdatePolicy from "./update/UpdatePolicy";

const PolicyRow = ({
  policy,
  handleDelete,
  isActionMenuOpen,
  setIsActionMenuOpen,
  toggleActionMenu,
}) => {
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  const handleUpdate = () => {
    setIsDialogVisible(true);
    setIsActionMenuOpen(false);
  };

  const handleCloseDialog = () => {
    setIsDialogVisible(false);
  };

  const handleActionClick = () => {
    toggleActionMenu(policy.id);
  };

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  return (
    <>
      <tr key={policy.id} style={{ position: "relative" }}>
        <td>{policy.policyName}</td>
        <td>{policy.policyVersion}</td>
        <td>{policy.visibility ? "Visible" : "Hidden"}</td>
        <td>{policy.policyFormat}</td>
        <td>{formatDate(policy.updatedAt)}</td>
        <td>
          <img src={ActionsIcon} alt="Actions" onClick={handleActionClick} />
          {isActionMenuOpen && (
            <ActionMenu
              actions={[
                { label: "Update", handler: handleUpdate },
                { label: "Delete", handler: handleDelete },
              ]}
              onClose={() => toggleActionMenu(null)}
            />
          )}
        </td>
      </tr>
      <UpdatePolicy
        visible={isDialogVisible}
        onHide={handleCloseDialog}
        policyId={policy.id}
        onClose={handleCloseDialog}
      />
    </>
  );
};

export default PolicyRow;
