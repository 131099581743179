// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getFirestore} from "@firebase/firestore"
import {getStorage} from "firebase/storage"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBrC5_SIB3DISs8jrs_5yVnuqDggunoZN0",
  authDomain: "incuhub-fd693.firebaseapp.com",
  projectId: "incuhub-fd693",
  storageBucket: "incuhub-fd693.appspot.com",
  messagingSenderId: "590557081765",
  appId: "1:590557081765:web:bf1d3dcec7cf39bcef5d4c",
  measurementId: "G-L0X6Y2R05W"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const imgstore = getStorage(app);
export const firestore = getFirestore(app);
//export default getFirestore(app);