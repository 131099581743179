import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
} from "@mui/material";
import CustomCloseIcon from "../../../utils/CustomCloseIcon";

const UserDetailsDialog = ({
  visible,
  user,
  onHide,
  handleOpenEditMembership,
  onUpdateHide
}) => {
  if (!user) return null;

  return (
    <Dialog open={visible} onClose={onHide} maxWidth="sm" fullWidth>
      <DialogTitle>User Details</DialogTitle>
      <div
        className="closeIconn"
        style={{
          position: "absolute",
          right: "2%",
          top: "6%",
          cursor: "pointer",
        }}
        onClick={onHide}
      >
        <CustomCloseIcon fill="#ff0000" />
      </div>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2">First Name:</Typography>
            <Typography variant="body1">{user.firstname || "--"}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2">Last Name:</Typography>
            <Typography variant="body1">{user.lastname || "--"}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2">Email:</Typography>
            <Typography variant="body1">{user.email || "--"}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2">Phone:</Typography>
            <Typography variant="body1">{user.phone || "--"}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2">Membership:</Typography>
            <Typography variant="body1">{user.membership || "--"}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2">Visibility:</Typography>
            <Typography variant="body1">{user.visibility || "--"}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      {/* Update Membership Button */}
      <div
        className="btn-container"
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "10px",
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={() => handleOpenEditMembership(user)}
          sx={{
            mb: 2, // Adds space below the button
            backgroundColor: "#66a3a6", // Custom background color
            color: "#fff", // White text for contrast
            "&:hover": {
              backgroundColor: "#558f91", // Darker shade on hover
            },
            fontWeight: "bold", // Bold text
            padding: "10px 0", // Increased padding for a larger button
            borderRadius: "8px", // Slightly rounded corners
            width: "70%", // Set width to 70%
            mx: "auto", // Centers the button horizontally
            display: "block", // Ensures it's a block-level element for centering
            border: "none", // Removes the border
          }}
        >
          CLICK HERE TO UPDATE YOUR MEMBERSHIP
        </Button>
      </div>
    </Dialog>
  );
};

export default UserDetailsDialog;
