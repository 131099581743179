import React, { useState,useEffect } from "react";
import TopNavBar from "../../components/TopNavBar";
import Sidebar from "../../components/Sidebar";
import "./Settings.css";
import UiSettings from "./settingspages/UiSettings";
import Filters from "./settingspages/Filters";

function Settings() {
  const [activeSection, setActiveSection] = useState(1);
  const uiSettingSection = () => {
    setActiveSection(1);
  };
  const filtersSection = () => {
    setActiveSection(2);
  };

  return (
    <>
      <div className="navBarWrapper">
        <Sidebar />
        {/* <==> to homecontainer */}
        <div className="contentWrapper">
          <TopNavBar />
          {/* Content of the page */}
          <div className="settingsWrapper">
            <div className="settingsHeadingWrapper">
              <div
                className={`settingHeading ${
                  activeSection === 1 ? "active" : ""
                }`}
                onClick={uiSettingSection}
                id="uiSettingId"
              >
                <h2>UI settings</h2>
              </div>
              <div
                className={`filterHeading ${
                  activeSection === 2 ? "active" : ""
                }`}
                onClick={filtersSection}
                id="filtersId"
              >
                <h2>Filters</h2>
              </div>
            </div>
            {/* image upload section */}
            {activeSection === 1 ? <UiSettings /> : <Filters />}
          </div>
          {/*End of content  */}
        </div>
      </div>
    </>
  );
}

export default Settings;
