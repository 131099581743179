import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { toast } from 'react-toastify';

function PrivateRoutes() {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userType = userData.usertype;
  console.log("For protecting routes", userData)
  let auth = { 'token': true }
  return (
    userType === 'admin' ? (<Outlet />) : <>{toast.error("This email does not have priviledge to access the admin portal")}<Navigate to="/" /> </>
  )
}

export default PrivateRoutes