import React, { useEffect, useState } from "react";
import "./ProfileForm.css";
import ProfileFormImg from "../../../src/assets/png/ProfileFormImg.png";
import ProfileSelectionIcon from "../../../src/assets/svg/profilecamera.svg";
import { useQuery } from "react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { fetchUserDetails } from "../../utils/fetchData";
import { toast } from "react-toastify";

const apiURL = process.env.REACT_APP_API_URL;

function ProfileForm({ onClose }) {
  const fetchUserDetails = async () => {
    try {
      const token = localStorage.getItem("token");
      const { data } = await axios.get(`${apiURL}/v1/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const userData = data.body[0];

      if (userData.image.includes("http")) {
        // const imageResponse = await axios.get(`${userData.image}`,
        // {
        //   responseType: 'arraybuffer',
        // });

        // const blob = new Blob([imageResponse.data],{ type: 'image/jpeg' })
        // const url = URL.createObjectURL(blob)
        userData.imageUrl = userData.image;
      } else {
        const imageResponse = await axios.get(
          `${apiURL}/v1/files/${userData.image}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            responseType: "arraybuffer",
          }
        );

        const blob = new Blob([imageResponse.data], { type: "image/jpeg" });
        const url = URL.createObjectURL(blob);
        userData.imageUrl = url;
      }

      console.log("userData", userData);

      return userData;
    } catch (error) {
      console.log(error);
    }
  };

  const [userData, setUserData] = useState({});
  const [tempFlag, setTempFlag] = useState(true);
  const [profileImage, setProfileImage] = useState(ProfileFormImg);
  const { data, isLoading } = useQuery("userData", fetchUserDetails, {
    onSuccess: (data) => {
      setUserData(data);
      console.log("userData", userData);
      if (tempFlag && data?.imageUrl) {
        setTempFlag(false);
        setProfileImage(data.imageUrl);
      }
    },
  });

  const [selectedFile, setSelectedFile] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
        setSelectedFile(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleOnInputChange = (input) => {
    const { name, value } = input.target;
    setUserData((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const handleOnSubmitForm = async (e) => {
    e.preventDefault();
    try {
      let imagePath = null;
      const token = localStorage.getItem("token");

      if (selectedFile) {
        const imageFormData = new FormData();
        imageFormData.append("file", selectedFile);
        const imageUploadResponse = await axios.post(
          `${apiURL}/v1/files`,
          imageFormData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        imagePath = imageUploadResponse.data.body[0].filename;
      }

      console.log("imagePath", imagePath);
      const formData = new FormData();
      formData.append("image", imagePath || userData.image);
      formData.append("firstname", userData.firstname);
      formData.append("lastname", userData.lastname);
      formData.append("email", userData.email);
      // formData.append("phone", userData.phone);
      // formData.append("location", userData.location);
      // formData.append("organisation", userData.organisation);
      console.log("Here manage account", userData);
      const response = await axios.put(
        `${apiURL}/v1/users/${userData.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json",
          },
        }
      );

      onClose();
      window.location.reload();
      toast.success("Data Saved Successfully!");
    } catch (error) {
      console.log(error.message);
    }
  };

  if (isLoading) {
    return (
      <>
        <div className="formWrapper">
          <div className="formProfileImgWrapper">
            <div className="formProfileImg">
              <img src={profileImage} alt="Profile" className="profileImage" />
              <label htmlFor="imageInput">
                <img
                  src={ProfileSelectionIcon}
                  alt="Profile Selection"
                  className="overlayIcon"
                />
              </label>
              <input
                type="file"
                id="imageInput"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
            </div>
            {/* <div className="profileSelectionIcon">
          <img src={ProfileSelectionIcon} alt="" />
         </div> */}
          </div>
          Loading.....
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="formWrapper">
          <div className="formProfileImgWrapper">
            <div className="formProfileImg">
              <img src={profileImage} alt="Profile" className="profileImage" />
              <label htmlFor="imageInput">
                <img
                  src={ProfileSelectionIcon}
                  alt="Profile Selection"
                  className="overlayIcon"
                />
              </label>
              <input
                type="file"
                id="imageInput"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
            </div>
            {/* <div className="profileSelectionIcon">
          <img src={ProfileSelectionIcon} alt="" />
         </div> */}
          </div>
          <form onSubmit={handleOnSubmitForm} action="">
            <div className="formFlexClass">
              <div className="formInputWrapper">
                <label htmlFor="fName">First Name</label>
                <input
                  type="text"
                  name="firstname"
                  id="fName"
                  onChange={handleOnInputChange}
                  value={userData?.firstname}
                  required
                />
              </div>
              <div className="formInputWrapper">
                <label htmlFor="lName">Last Name</label>
                <input
                  type="text"
                  name="lastname"
                  id="lName"
                  onChange={handleOnInputChange}
                  value={userData?.lastname}
                  required
                />
              </div>
            </div>
            {/* mail and contact */}
            <div className="formFlexClass">
              <div className="formInputWrapper">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  onChange={handleOnInputChange}
                  value={userData?.email}
                  disabled
                  className="disabledClass"
                  required
                />
              </div>
              <div className="formInputWrapper">
                <label htmlFor="phNum">Phone number</label>
                <input
                  type="number"
                  name="phone"
                  id="phNum"
                  onChange={handleOnInputChange}
                  value={userData?.phone}
                  disabled
                  className="disabledClass"
                />
              </div>
            </div>
            {/* location  */}
            {/* <div className="locationInputWrapper">
              <label htmlFor="lName">Location</label>
              <input type="text" />
            </div> */}
            {/* location and organisation --new update */}
            {/* <div className="formFlexClass">
              <div className="formInputWrapper">
                <label htmlFor="location">Location</label>
                <input type="text" onChange={handleOnInputChange} name="location" value={userData?.location} required/>
              </div>
              <div className="formInputWrapper">
                <label htmlFor="org">Organisation</label>
                <input type="text" onChange={handleOnInputChange} name="organisation" value={userData?.organisation} required/>
              </div>
            </div> */}
            <div className="buttonWrapper">
              <button type="submit">Save</button>
              <button onClick={onClose}>Cancel</button>
            </div>
          </form>
        </div>
      </>
    );
  }
}

export default ProfileForm;
