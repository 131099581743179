import React, { useState,useEffect,useContext } from 'react';
import { Link } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import HamburgerMenuItem from './HamburgerMenuItem';
import './HamburgerMenu.css'


import Logo from "../assets/png/logoImg.png";
import { ReactComponent as MeetingsRoomIcon } from "../../src/assets/svg/MeetingsRoomIcon.svg";
import { ReactComponent as CoachesIcon } from "../../src/assets/svg/CoachesIcon.svg";
import { ReactComponent as BookingRequestsIcon } from "../../src/assets/svg/BookingRequestsIcon.svg";
import { ReactComponent as NewsIcon } from "../../src/assets/svg/NewsIcon.svg";
import { ReactComponent as SettingsIcon } from "../../src/assets/svg/SettingsIcon.svg";
import { ReactComponent as StaffIcon } from "../../src/assets/svg/StaffIcon.svg";
import { ReactComponent as HamburgerMeunIcon } from "../assets/svg/HamburgerMeunIcon.svg";
import { ReactComponent as UserRequestIcon } from "../../src/assets/svg/userreq.svg";
import MyContext from '../utils/MyContext';

function HamburgerMenu () {

  const svgFolderLocation = '../assets/svg'
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      // Update the state based on the screen width
      setIsHidden(window.innerWidth < 450);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Initial check on component mount
    handleResize();

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  // use context
  const {contextValue, setContextValue} = useContext(MyContext);
  
 
  
  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
    setContextValue(true)
  };
  console.log("use context",contextValue)
//welcomepage css holds the solution for menu logo size, if not resolved
  return (
    <div className='buttonAndMenuWrapper'>

      {isHidden && 
        <div className='menuButtonWrapper'>
          <div onClick={handleMenuToggle} style={{ cursor: 'pointer' }}>
            <HamburgerMeunIcon width="40" height="35"  />
          </div>
        </div>
        }

      {isMenuOpen && (
        <Menu  width={"250px"} isOpen={isMenuOpen} onClose={()=>{setIsMenuOpen(false); setContextValue(false)}}>

          <div className='menuLogoWrapper'>
            <img src={Logo} alt="Logo" />
          </div>
          <hr />
          {/* <div className='backButtonWapper' onClick={()=>{setIsMenuOpen(false)}}>
            <HamburgerMeunIcon width="40" height="35" />
          </div> */}
          {/* <hr/> */}
          <HamburgerMenuItem url={'/meetingrooms'} icon={<MeetingsRoomIcon/>} id={'meetingRooms'} itemTitle={'Meeting Rooms'} />
          <HamburgerMenuItem url={'/coaches'} icon={<CoachesIcon/>} id={'coahes'} itemTitle={'Coaches'} />
          <HamburgerMenuItem url={'/bookingrequests'}icon={<BookingRequestsIcon/>} id={'bookingRequests'} itemTitle={'Booking Requests'} />
          <HamburgerMenuItem url={'/news'} icon={<NewsIcon/>} id={'news'} itemTitle={'News'} />
          <HamburgerMenuItem url={'/settings'} icon={<SettingsIcon/>} id={'settings'} itemTitle={'Settings'} />
          <HamburgerMenuItem url={'/staff'} icon={<StaffIcon/>} id={'staff'} itemTitle={'Staff'} />
          <HamburgerMenuItem url={'/userrequests'} icon={<UserRequestIcon />} id={'userRequest'} itemTitle={'User Requests'} />
        </Menu>
      )}
    </div>
  );
};

export default HamburgerMenu;
