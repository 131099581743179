//import logo from './logo.svg';
import "./App.css";
import { BrowserRouter, Routes, Route, Router } from "react-router-dom";
import WelcomePage from "./pages/welcomepage/WelcomePage";
import MeetingRooms from "./pages/meetingrooms/MeetingRooms";
import Coaches from "./pages/coaches/Coaches";
import AddNewRooms from "./pages/meetingrooms/rooms/AddNewRooms";
import EditRoom from "./pages/meetingrooms/rooms/EditRoom";
import BookingRequests from "./pages/bookingrequests/BookingRequests";
import News from "./pages/news/News";
import Settings from "./pages/settings/Settings";
import Staff from "./pages/staff/Staff";
import AddNewCoache from "./pages/coaches/coachepages/AddNewCoache";
import EditCoach from "./pages/coaches/coachepages/EditCoach";
import DetailsPage from "./pages/bookingrequests/DetailsPage";
import AddNewNews from "./pages/news/newspages/AddNewNews";
import EditNews from "./pages/news/newspages/EditNews";
import AddStaffPage from "./pages/staff/staffpages/AddStaffPage";
import EditStaffPage from "./pages/staff/staffpages/EditStaffPage";
import ForgotPassword from "./pages/welcomepage/ForgotPassword";
import { QueryClient, QueryClientProvider } from "react-query";
import PrivateRoutes from "../src/utils/PrivateRoutes";
import UserRequests from "./pages/userrequests/UserRequests";
import React, { createContext, useState } from "react";
import MyContext from "./utils/MyContext";
import Policy from "./pages/policy/Policy";
import CreatePolicy from "./pages/policy/create/CreatePolicy";
import Events from "./pages/events/Events";
import CreateEvent from "./pages/events/CreateEvent";
import EventDetails from "./pages/events/EventDetails";
import ActionsMenu from "./pages/policy/ActionMenu";
import UpdatePolicy from "./pages/policy/update/UpdatePolicy";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReviewMod from "./pages/review_moderation/ReviewMod";

function App() {
  const queryClient = new QueryClient();
  const [contextValue, setContextValue] = useState(false);

  return (
    <>
      <div className="mobile-blocked">
        <p>Application is not available currently on mobile devices, we are working on it. <br />Please use a desktop browser.</p>
      </div>
      <div className="desktop-content">
        <MyContext.Provider value={{ contextValue, setContextValue }}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <ToastContainer />
              <Routes>
                <Route path="/" element={<WelcomePage />}></Route>
                {/* <PrivateRoutes> */}
                <Route path="/meetingrooms" element={<MeetingRooms />} />
                <Route element={<PrivateRoutes />}>
                  <Route path="/addnewrooms" element={<AddNewRooms />} />
                  <Route path="/editrooms" element={<EditRoom />} />
                  <Route path="/coaches" element={<Coaches />} exact />
                  <Route path="/addnewcoaches" element={<AddNewCoache />} />
                  <Route path="/editcoaches/:id" element={<EditCoach />} />
                  <Route path="/bookingrequests" element={<BookingRequests />} />
                  <Route path="/detailspage" element={<DetailsPage />} />
                  <Route path="/news" element={<News />} />
                  <Route path="/addnewnews" element={<AddNewNews />} />
                  <Route path="/editnews" element={<EditNews />} />
                  <Route path="/settings" element={<Settings />} />
                  <Route path="/staff" element={<Staff />} />
                  <Route path="/addstaff" element={<AddStaffPage />} />
                  <Route path="/editstaff" element={<EditStaffPage />} />
                  <Route path="/userrequests" element={<UserRequests />} />
                  <Route path="/policy" element={<Policy />} />
                  <Route path="/createPolicy" element={<CreatePolicy />} />
                  <Route path="events" element={<Events />} />
                  <Route path="/createEvent" element={<CreateEvent />} />
                  <Route path="/eventDetails/:id" element={<EventDetails />} />
                  <Route path="/updatePolicy" element={<UpdatePolicy />} />
                  <Route path="/review-moderation" element={<ReviewMod />} />
                </Route>
              </Routes>
            </BrowserRouter>
            <footer
              style={{
                position: "fixed",
                width: "100%",
                bottom: "0px",
                zIndex: "1000",
                backgroundColor: "#ffffff",
                height: "35px",
                color: "rgba(102, 163, 166, 1)",
                fontWeight: "500",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  height: "100%",
                }}
              >
                © Axzora Inc.
              </div>
            </footer>
          </QueryClientProvider>
        </MyContext.Provider>
      </div>
    </>
  );
}

export default App;
