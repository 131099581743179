import { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
} from "@mui/material";
import axios from "axios";
import "./bookingreview.css";

const BookingReviewDialog = ({
  bookingType,
  selectedBookingRequest,
  setSelectedBookingRequest,
}) => {
  const [review, setReview] = useState();
  const [isReviewDisabled, setIsReviewDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const updateMeetingRoomReview = async () => {
    const token = localStorage.getItem("token");
    const updatedReview = {
      ...review,
      isDisabled: isReviewDisabled,
    };

    const url = `${process.env.REACT_APP_API_URL}/v1/reviews/${updatedReview?.id}?admin=true`;
    const response = await axios.patch(`${url}`, updatedReview, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response?.status === 200) {
      toast.success("Review Updated Successfully");
      setSelectedBookingRequest();
    }
  };

  const updateCoachReview = async () => {
    const token = localStorage.getItem("token");
    const updatedReview = {
      ...review,
      isDisabled: isReviewDisabled,
    };

    const url = `${process.env.REACT_APP_API_URL}/v1/coachreviews/${updatedReview?.id}?admin=true`;
    const response = await axios.patch(`${url}`, updatedReview, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response?.status === 200) {
      toast.success("Review Updated Successfully");
      setSelectedBookingRequest();
    }
  };

  const updateReview = () => {
    if (bookingType?.type === "meeting") {
      updateMeetingRoomReview();
    } else {
      updateCoachReview();
    }
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    (async () => {
      try {
        setIsLoading(true);
        if (bookingType?.type === "meeting") {
          const url = `${process.env.REACT_APP_API_URL}/v1/reviews/room/${selectedBookingRequest?.bookedRoomId}?userId=${selectedBookingRequest?.user?.id}`;
          const response = await axios.get(`${url}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const _review = response?.data?.body[0][0];
          setReview(response?.data?.body[0][0] || []);
          console.log("_review?.isDisabled", _review?.isDisabled, _review);
          setIsReviewDisabled(_review?.isDisabled);
        } else {
          const url = `${process.env.REACT_APP_API_URL}/v1/coachreviews/coach/${selectedBookingRequest?.bookedCoachId}?userId=${selectedBookingRequest?.user?.id}`;
          const response = await axios.get(`${url}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const _review = response?.data?.body[0][0];
          setReview(response?.data?.body[0][0] || []);
          console.log("_review?.isDisabled", _review?.isDisabled, _review);
          setIsReviewDisabled(_review?.isDisabled);
        }
      } catch (error) {
        toast.error("something went wrong");
      } finally {
        setIsLoading(false);
      }
    })();
  }, [bookingType, selectedBookingRequest]);

  function formatDateTime(isoString) {
    const date = new Date(isoString);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return `${formattedDate} ${formattedTime}`;
  }

  return (
    !isLoading && (
      <Dialog
        open={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="booking-review-dialog"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="booking-review-header">
            <div>Review</div>
            <div
              className="booking-review-cancel-dialog"
              onClick={() => setSelectedBookingRequest(false)}
            >
              X
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="booking-review-container">
              {Object.keys(review).length > 0 ? (
                <>
                  <div className="booking-sub-header-review">
                    <div>Rating : {review?.rating}</div>
                    <div>{formatDateTime(review?.createdAt)}</div>
                  </div>
                  <div>
                    <div className="booking-review-description">
                      {review?.description}
                    </div>
                  </div>
                  <div className="review-disable">
                    <div>Disable</div>
                    <Switch
                      checked={isReviewDisabled}
                      onChange={(e) => setIsReviewDisabled((prev) => !prev)}
                    />
                  </div>
                </>
              ) : (
                <div>
                  <h1 style={{ textAlign: "center" }}>No Review Found</h1>
                </div>
              )}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {Object.keys(review).length > 0 && (
            <div className="booking-review-save">
              <button onClick={updateReview}>Save</button>
            </div>
          )}
        </DialogActions>
      </Dialog>
    )
  );
};

export default BookingReviewDialog;
