import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as RightArrow } from "../../assets/svg/right_arrow.svg";
import { toast } from "react-toastify";

const EventCard = ({
  eventName,
  eventDate,
  eventStartTime,
  eventEndTime,
  eventCapacity,
  eventImage,
  eventId,
  updateEvents,
}) => {
  const [isDropdownSelected, setIsDropdownSelected] = useState(false);
  const navigate = useNavigate();

  const cardClickHandler = () => {
    navigate(`/eventDetails/${eventId}`);
  };

  const menuHandler = (e) => {
    e.stopPropagation();
    setIsDropdownSelected((prev) => !prev);
  };

  const deleteEventHandler = async (e) => {
    setIsDropdownSelected(false);
    try {
      e.stopPropagation();
      const token = localStorage.getItem("token");
      const eventDeleteResponse = await axios.delete(
        `${process.env.REACT_APP_API_URL}/v1/events/${eventId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (eventDeleteResponse?.data?.status === 200) {
        updateEvents(eventId);
        setIsDropdownSelected(false);
      }
    } catch (error) {
      console.log(error, "error");
      toast.error(error?.response?.data?.message);
    }
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);

    let hours = date.getHours();
    const minutes = date.getMinutes();

    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12 || 12;

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const day = days[date.getUTCDay()];
    const dayOfMonth = date.getUTCDate();
    const month = months[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    return `${day}, ${dayOfMonth} ${month} ${year}`;
  };

  const editEventHandler = async (e) => {
    try {
      e.stopPropagation();
      const token = localStorage.getItem("token");
      const eventResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/events/${eventId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (eventResponse.data.body[0]?.eventRegistrations === 0) {
        localStorage.setItem(
          "eventDetails",
          JSON.stringify(eventResponse.data.body[0])
        );
        navigate("/createEvent");
      } else {
        toast.warn("Cannot Edit Event because of registrations");
        setIsDropdownSelected(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="event_card">
      <div className="event_image_container">
        <img
          className="event_image"
          src={`${process.env.REACT_APP_API_URL}/v1/files/${eventImage}`}
          alt="event_image"
        />
        <div className="image_overlay"></div>
      </div>
      <img
        className="kebab_menu"
        src="./kebab_menu.svg"
        onClick={menuHandler}
        alt="menu"
      />
      {isDropdownSelected && (
        <div className="event_card_dropdown">
          <div onClick={deleteEventHandler}>Delete</div>
          <div onClick={editEventHandler}>Edit</div>
        </div>
      )}
      <div className="event_card_details">
        <div className="card_event_name">
          <span>{eventName}</span>
        </div>
        <div className="card_event_date">
          Event On: <span>{formatDate(eventDate)}</span>
        </div>
        <div className="card_event_time">
          Event Timings:{" "}
          <span>
            {formatTime(eventStartTime)} To {formatTime(eventEndTime)}
          </span>
        </div>
        <div className="card_event_capacity">
          Capacity:<span> {eventCapacity}</span>
        </div>
      </div>
      <div className="card_view" onClick={cardClickHandler}>
        <span>View</span>
        <RightArrow />
      </div>
    </div>
  );
};

export default EventCard;
