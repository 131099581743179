import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import TopNavBar from "../../components/TopNavBar";
import LoadingOverlay from "react-loading-overlay-ts";
import "./events.css";
import { useEffect, useState } from "react";
import EventCard from "./EventCard.tsx";
import axios from "axios";

const Events = () => {
  const [events, setEvents] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const navigate = useNavigate();

  const handlePageClick = (page) => {
    setPageNumber(page);
  };

  useEffect(() => {
    (async () => {
      try {
        const token = localStorage.getItem("token");
        const eventsResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/events`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const sortedEvents = eventsResponse?.data?.body[0].sort((a, b) => {
          return new Date(a.eventDate) - new Date(b.eventDate);
        });
        setEvents(sortedEvents);
      } catch (error) {}
    })();
  }, []);

  const updateEvents = (removedEventId) => {
    const updatedEvents = events?.filter(
      (_event) => _event?.id !== removedEventId
    );
    setEvents(updatedEvents);
  };

  const createEventHandler = () => {
    localStorage.removeItem("eventDetails");
    navigate("/createEvent");
  };
  return (
    <LoadingOverlay active={false} spinner text="Loading your content...">
      <div className="navBarWrapper">
        <Sidebar />
        <div className="contentWrapper">
          <TopNavBar />
          {/* Content of the page */}
          <div className="EventViewWrapper">
            <div className="EventNavbarSection">
              <button className="createNewButton" id="addNewItemId">
                <div onClick={createEventHandler}>Create Event</div>
              </button>
            </div>
            <div className="events">
              {events?.length > 0 ? (
                events
                  ?.slice((pageNumber - 1) * 8, pageNumber * 8)
                  .map((_event) => {
                    const {
                      id,
                      eventName,
                      eventDate,
                      eventStartTime,
                      eventEndTime,
                      eventCapacity,
                      eventImage,
                    } = _event;
                    return (
                      <EventCard
                        eventName={eventName}
                        eventDate={eventDate}
                        eventStartTime={eventStartTime}
                        eventEndTime={eventEndTime}
                        eventCapacity={eventCapacity}
                        eventImage={eventImage}
                        eventId={id}
                        updateEvents={updateEvents}
                      />
                    );
                  })
              ) : (
                <div className="no_events">No Events Available</div>
              )}
            </div>
          </div>
          <div className="paginationNumbers">
            {Array(Math.ceil(events.length / 8))
              .fill("")
              .map((_, index) => (
                <button
                  key={index}
                  onClick={() => handlePageClick(index + 1)}
                  className={pageNumber === index + 1 ? "active" : ""}
                >
                  {index + 1}
                </button>
              ))}
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default Events;
