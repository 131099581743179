import React, { useContext, useState } from "react";
import "./ProfileWidget.css";
import crossIcon from "../../../src/assets/png/crossIcon.png";
import ProfileForm from "./ProfileForm";

import { ReactComponent as CredentialsIcons } from "../../../src/assets/svg/credentialsIcons.svg";
import { ReactComponent as UserIcon } from "../../../src/assets/svg/User.svg";
import CredentialsForm from "./CredentialsForm";
import MyContext from "../../utils/MyContext";

function ProfileWidget({ onClose }) {
  const [activeForm, setActiveForm] = useState(1);
  const {contextValue, setContextValue} = useContext(MyContext);
  const switchProfileSection = () => {
    setActiveForm(1);
  };
  const switchCredentialsSection = () => {
    setActiveForm(2);
  };
  return (
    <>
      <div className="profileFormWrapper">
        <section className="formSection">
          <div className="profileFormHeaderWrapper">
            <div className="profileFormHeader">
              <div
                className="profileTag active"
              >
                <UserIcon />
                <h2>Profile</h2>
              </div>
              {/* <div
                className={`profileTag ${activeForm === 2 ? "active" : ""}`}
                onClick={switchCredentialsSection}
              >
                <CredentialsIcons />
                <h2>Credentials</h2>
              </div> */}
            </div>
            <span>
              <img src={crossIcon} alt="" onClick={onClose} />
            </span>
          </div>
          {/* Profile form */}
        
            <ProfileForm onClose={onClose} />
        
        </section>
      </div>
    </>
  );
}

export default ProfileWidget;
