const StarIcon = ({ color, width, height }) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 77"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group" 

      
      >
        <path
          id="Vector"
          d="M79.4613 29.7243L50.5884 27.8261L39.7148 0.508789L28.8412 27.8261L0 29.7243L22.121 48.5073L14.8621 76.9999L39.7148 61.2906L64.5677 76.9999L57.3088 48.5073L79.4613 29.7243Z"
          fill={color}
          fill-opacity="0.6"
          stroke="black"
          stroke-width="5"
        />
      </g>
    </svg>
  );
  
  export default StarIcon;
  